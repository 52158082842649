import React, {Component} from 'react';
import { connect }        from 'react-redux';

import './index.scss';

class Home extends Component {
    
    render() {
        const Text = this.props.Gd.translationsArr ? this.props.Gd.translationsArr : 'text' ;
        return (
            <div className="home">
                <h1>{ Text.home_header }</h1>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
        Gd:store.gd
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {}
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Home);
