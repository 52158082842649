import ApiManager from "../index";
import Actions from "redux/actions";
import store from "../../redux";
import * as popupTypes from "constants/popup-types";
import { googleAnalyticsManager } from "app/GoogleAnalytics/GoogleAnalyticsManager";

class Api extends ApiManager {
  constructor() {
    if (!Api.instance) {
      super();
      Api.instance = this;
    }

    return (Api.instance = this);
  }

  getHostUrl = (props = {}) => {
    const onSuccess = (response) => {
      let api = {
        base_url: response.data.host,
        get_methods: response.data.get_methods,
        get_url: response.data.get_url,
      };
      this._updateApiParams(api);
    };
    return this._execute(props, "getHostUrl", onSuccess);
  };

  generalDeclaration = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setGd(response.data));
    };

    return this._execute(props, "generalDeclaration", onSuccess);
  };

  sendInsuranceForm = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setLoader(false));

      if (response.status) {
        googleAnalyticsManager.lead("Lead - Insurance Agents");

        store.dispatch(
          Actions.addPopup({
            type: popupTypes.Treatment,
            payload: {},
          })
        );
      } else {
        store.dispatch(
          Actions.addPopup({
            type: popupTypes.API_ERROR,
            payload: { text: "קרתה שגיאה, אנא נסו שנית" },
          })
        );
      }
    };

    store.dispatch(Actions.setLoader(true));

    return this._execute(props, "sendInsuranceForm", onSuccess);
  };

  getManufacturersAndModels = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setLoader(false));

      if (response.status) {
        store.dispatch(Actions.setModelsAndManufacters(response.data));
      } else {
        store.dispatch(
          Actions.addPopup({
            type: popupTypes.API_ERROR,
            payload: { text: "קרתה שגיאה, אנא נסו שנית" },
          })
        );
      }
    };
    store.dispatch(Actions.setLoader(true));

    return this._execute(props, "getWsCars", onSuccess);
  };

  sendTreatmentForm = (props = {}) => {
    const onSuccess = (response) => {
      if (response.status) {
        googleAnalyticsManager.lead(
          "Lead – Home car repair"
        );
        store.dispatch(
          Actions.addPopup({
            type: popupTypes.Treatment,
            payload: { callback: props.onSuccessCallback },
          })
        );
      } else {
        store.dispatch(
          Actions.addPopup({
            type: popupTypes.API_ERROR,
            payload: { text: "קרתה שגיאה, אנא נסו שנית" },
          })
        );
      }
    };
    return this._execute(props, "sendTreatmentForm", onSuccess);
  };

  searchGovCar = (props = {}) => {
    const onSuccess = (response) => {
      props.onLicenseVaild(response.data);
      googleAnalyticsManager.licenseNum();
    };
    const onFailure = (response) => {
      props.onLicenseNotValid();
    };
    return this._execute(props, "searchGovCar", onSuccess, onFailure);
  };
  getCityStreets = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setStreets(response.data));
      // props.onSuccess(response.data);
    };
    const onFailure = (response) => {};
    return this._execute(props, "GetCityStreets", onSuccess, onFailure);
  };

  sendBatteryForm = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setLoader(false));

      if (response.status) {
        googleAnalyticsManager.lead("Lead - Battery up to me");

        store.dispatch(
          Actions.addPopup({
            type: popupTypes.Treatment,
            payload: {},
          })
        );
      } else {
        store.dispatch(
          Actions.addPopup({
            type: popupTypes.API_ERROR,
            payload: { text: "קרתה שגיאה, אנא נסו שנית" },
          })
        );
      }
    };
    store.dispatch(Actions.setLoader(true));

    return this._execute(props, "sendBatteryForm", onSuccess);
  };

  SendDynamicForm = (props = {}) => {
    const onSuccess = (response) => {
      store.dispatch(Actions.setLoader(false));

      if (response.status) {
        googleAnalyticsManager.lead(response.data);

        store.dispatch(
          Actions.addPopup({
            type: popupTypes.Treatment,
            payload: {},
          })
        );
      } else {
        store.dispatch(
          Actions.addPopup({
            type: popupTypes.API_ERROR,
            payload: { text: "קרתה שגיאה, אנא נסו שנית" },
          })
        );
      }
    };
    store.dispatch(Actions.setLoader(true));
    return this._execute(props, "SendDynamicForm", onSuccess);
  };
}

const instance = new Api();
Object.freeze(instance);
export default instance;
