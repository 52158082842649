import "./index.scss";
import Timer from "components/common/timer";
function Tool() {
  return (
    <div className="tool-wrapper with-preview">
      <div className="left">
        <div className="component">
          <div className="component-title">Timer</div>
          <div className="component-usage">Countdown Timer</div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Location:</b>
          </div>
          <div className="label">
            /components/ common/ timer - parent component.
          </div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Props:</b>
          </div>
          <div className="label">timeInSeconds - time to count in seconds</div>
          <div className="label">
            onTimeIsUpFunction - a function callback to trigger when the time is
            over
          </div>
        </div>
        <div className="code-sample">
          <code>
            {`<Timer
              timeInSeconds={120}
              onTimeIsUpFunction={onTimeIsUpHandler}
            />`}
          </code>
        </div>
      </div>
      <div className="right">
        <Timer
          timeInSeconds={120}
          onTimeIsUpFunction={() => {
            alert("done");
          }}
        />
      </div>
    </div>
  );
}
export default Tool;
