import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Actions from "redux/actions";
import ScroolBar from "containers/ScroolBar/ScroolBar";

import "./CustomAutoComplete.scss";

function CustomAutoComplete(props) {
  const {
    options,
    selectedId = -1,
    name = "missing-name-prop",
    disabled = false,
    className,
    onSelected,
    isValid = true,
    dropDownImg = undefined,
  } = props;
  const [placeholder, setPlaceholder] = useState(props.placeholder);

  const [isOpen, setIsOpen] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(-1);
  const dispatch = useDispatch();
  const listRef = useRef(null);
  useEffect(() => {
    dispatch(Actions.setBackdrop(isOpen));
  }, [isOpen]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSelected();
    if (selectedId === -1) {
      setQuery("");
    }
  }, [selectedId]);
  function onFocus() {
    setPlaceholder("");
  }

  function onFocusOut() {
    setPlaceholder(props.placeholder);
  }
  function handleClick(e) {
    //prevent form submission
    e.preventDefault();

    //fix for enter keyup event triggering click events - 0 = Enter key, 1 = click event
    if (!(e.detail === 0)) {
      setIsOpen((prevState) => {
        return !prevState;
      });
    }
  }

  function setSelected() {
 

    let result = "";
    if (selectedId !== -1) {
      result = filteredOptions.find((obj) => {
        return obj.id === selectedId;
      });
      onAutoCompleteQueryChange(result.text);
    }
  }

  function handleClickOutside() {
    setSelected();
    setIsOpen(false);
  }

  function handleOptionClick(event, id) {
    // hanles when user clicks an option

    event.preventDefault();
    setIsOpen(false);
    onSelected(name, id);
  }

  function filterOptions(options) {
    let filteredOptions = options.filter(
      (option) => option.text.indexOf(query) !== -1
    );

    return filteredOptions;
  }

  function getList(options) {
    let list = [];

    let counter = 0;
    for (let item in options) {
      let activeOption = counter === selectedId ? "active" : "";
      let highlightedOption = counter === highlightedItem ? " highlight" : "";

      list.push(
        <li
          className={"auto-option " + activeOption + highlightedOption}
          key={options[item].id}
          id={options[item].id}
          onMouseDown={(e) => handleOptionClick(e, options[item].id)}
        >
          {options[item].text}
        </li>
      );
      counter++;
    }
    return list;
  }

  function onAutoCompleteQueryChange(text) {
    setQuery(text);
  }

  const handleKeyUp = (event) => {
    let highlighted_item = highlightedItem;

    switch (event.key) {
      case "ArrowDown":
        highlighted_item + 1 > filteredOptions.length - 1
          ? (highlighted_item = 0)
          : highlighted_item++;
        setHighlightedItem(highlighted_item);
        break;

      case "ArrowUp":
        highlighted_item - 1 < 0
          ? (highlighted_item = filteredOptions.length - 1)
          : highlighted_item--;
        setHighlightedItem(highlighted_item);

        break;

      case "Backspace":
        onSelected(name, -1);
        break;

      case "Enter":
        const selected_item = filteredOptions[highlighted_item];

        handleOptionClick(event, selected_item.id);
        setHighlightedItem(-1);
        break;

      case "Escape":
        setIsOpen(false);
        setHighlightedItem(0);
        break;
      default:
        break;
    }
  };

  let filteredOptions = query === "" ? options : filterOptions(options);

  let activeClass = isOpen && options.length > 0 ? "active" : "";
  const vaildClassName = isValid ? " " : "unvalid ";
  return (
    <div
      className={
        "custom-autocomplete-wrapper " +
        vaildClassName +
        className +
        " " +
        activeClass +
        (disabled ? " disabled" : "")
      }
      onBlur={handleClickOutside}
      onKeyUp={handleKeyUp}
    >
      <input
        className="auto-input"
        name={name}
        type="text"
        autoComplete="new-password"
        placeholder={placeholder}
        onClick={(e) => handleClick(e)}
        onChange={(e) => onAutoCompleteQueryChange(e.target.value)}
        value={query}
        onFocus={onFocus}
        onBlur={onFocusOut}
      />
      {dropDownImg && (
        <div className="drop-down-img-wrapper" onClick={(e) => handleClick(e)}>
          <img src={dropDownImg} />
        </div>
      )}
      <div className="auto-menu">
        <ScroolBar isOpen={isOpen} listref={listRef}>
          <ul className="auto-list" ref={listRef}>
            {getList(filteredOptions)}
          </ul>
        </ScroolBar>
      </div>
    </div>
  );
}

export default CustomAutoComplete;
