import React, {Component} from 'react';
import { connect }        from 'react-redux';

import './index.scss';

class Tool extends Component {

    render() {
        return (
            <div className="tool-wrapper">
                <div className="component">
                    <div className="component-title">
                        ScrollToTop
                    </div>
                    <div className="component-usage">
                        Scrolls window to top on route changes.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">import ScrollToTop from 'components/routing/scroll_top';</div>
                </div>
                <div className="code-sample">
                    <code>
                        {`<ScrollToTop />`}
                    </code>
                </div>
                <div className="note">
                    <div className="note-label">note:</div>
                    <span>Disregards previous scrollTop position for history pop events(back button).</span>
                </div>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {}
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Tool);
