import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TopPart from "./top-part/top-part";
import CarDetails from "./CarDetails/CarDetails";
import PersonalDetails from "./PersonalDetails/PersonalDetails";

import Api from "api/requests";
import Actions from "redux/actions";
import Validate from "app/validation/validation";
import * as popupTypes from "constants/popup-types";
import * as Routes from "constants/routes";

import "./Treatment.scss";
import BackgroundMobile from "assets/background/background-mobile.svg";
import BackgroundDesktop from "assets/background/background-desktop.svg";

function Treatment(props) {
  const deviceState = useSelector((store) => store.deviceState);
  const text = useSelector((store) => store.gd.translationsArr);

  const dispatch = useDispatch();
  const [firstTry, setFirstTry] = useState(true);
  const [isFormVaild, setIsFormVaild] = useState(true);
  const backdrop = useSelector((store) => store.backdropState);
  const treatmentForm = useSelector((store) => store.treatmentForm);
  const history = useHistory();
  const [isLicenseEntred, setIsLicenseEntred] = useState(false);

  useEffect(() => {
    Api.getManufacturersAndModels({ config: { method: "get" } });
  }, []);

  const bakgroundImage = deviceState.isDesktop
    ? BackgroundDesktop
    : BackgroundMobile;

  const [formValidate, setFormValidate] = useState({
    license: {
      valid: true,
      rules: ["not_empty", "license"],
    },
    manufacturer: {
      valid: true,
      rules: ["required_select"],
    },
    model: {
      valid: true,
      rules: ["required_select"],
    },
    km: {
      valid: true,
      rules: ["not_empty"],
    },
    date: {
      valid: true,
      rules: ["not_empty"],
    },
    city: {
      valid: true,
      rules: ["required_select"],
    },
    street: {
      valid: true,
      rules: ["required_select"],
    },
    houseNo: {
      valid: true,
      rules: ["not_empty"],
    },
    firstName: {
      valid: true,
      rules: ["not_empty"],
    },
    lastName: {
      valid: true,
      rules: ["not_empty"],
    },
    phone: {
      valid: true,
      rules: ["not_empty", "cell"],
    },
    email: {
      valid: true,
      rules: ["not_empty", "email"],
    },
    notes: {
      valid: true,
      rules: ["no_validation"],
    },
  });

  function isValid(field) {
    return firstTry || formValidate[field].valid;
  }

  function handleSubmit(e) {
    e.preventDefault();

    let form_valid = true;
    let new_state = { ...formValidate };
    let validationObj;

    for (let field in formValidate) {
      validationObj = Validate(treatmentForm[field], formValidate[field].rules);

      new_state[field].valid = validationObj.valid;

      if (!validationObj.valid) {
        form_valid = false;
      }
    }

    setFormValidate(new_state);
    setFirstTry(false);
    setIsFormVaild(form_valid);

    if (form_valid) {
      const payload = {
        first_name: treatmentForm.firstName,
        last_name: treatmentForm.lastName,
        phone: treatmentForm.phone,
        email: treatmentForm.email,
        manufacturer_code: treatmentForm.manufacturer,
        manufacturer_name: treatmentForm.manufacturerName,
        model_code: treatmentForm.model,
        model_name: treatmentForm.modelName,
        car_number: treatmentForm.license,
        km: treatmentForm.km,
        pickup_date: dateFormat(treatmentForm.date, "yyyy-MM-dd"),
        pick_up_location_city_code: treatmentForm.city,
        pick_up_location_street_code: treatmentForm.street,
        pick_up_location_house: treatmentForm.houseNo,
      };
      if (treatmentForm.notes) {
        payload["notes"] = treatmentForm.notes;
      }
      dispatch(Actions.setLoader(true));

      function callback() {
        setTimeout(() => {
          history.push(Routes.root);
        }, 100);
      }

      Api.sendTreatmentForm({
        payload: payload,
        onSuccessCallback: callback,
      }).then(() => {
        dispatch(Actions.setLoader(false));
      });
    }
  }

  function handleChange(field, value) {
    let validationObj = Validate(value, formValidate[field].rules);

    let new_state = { ...formValidate };
    new_state[field].valid = validationObj.valid;
    setFormValidate(new_state);

    dispatch(Actions.updateTreatmentForm({ [field]: value }));
  }

  function dateFormat(inputDate, format) {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace("MM", month.toString().padStart(2, "0"));

    //replace the year
    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  }

  return (
    <div className="treatment-wrapper">
      <div className={`back-drop ${backdrop ? "active" : ""}`}></div>
      <div className="treatment-background">
        <img src={bakgroundImage}></img>
      </div>
      <div className={`treatment-content ${backdrop ? "backdrop-active" : ""}`}>
        <TopPart />

        <CarDetails
          handleChange={handleChange}
          isValidFunction={isValid}
          isLicenseEntred={isLicenseEntred}
          setIsLicenseEntred={setIsLicenseEntred}
          firstTry={firstTry}
          
        />
        <PersonalDetails
          handleChange={handleChange}
          isValidFunction={isValid}
        />
        <div className="actions">
          {!isFormVaild && (
            <span className="error-text-form">
              {isLicenseEntred
                ? text["service_centers_treatment_form_error"]
                : text["service_centers_treatment_form_error_not_send"]}
            </span>
          )}
          <button type="button" className="btn-submit" onClick={handleSubmit}>
            {text["service_centers_treatment_form_btn"]}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Treatment;
