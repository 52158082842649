import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Collapse from "../../../../components/common/collapse";

import { convertToSortedArray, getFullMediaUrl } from "app/functions";

import car from "assets/part-seven/seven-car.svg";
import form from "assets/part-seven/seven-form.svg";
import plus from "assets/part-seven/plus.svg";
import leftArrow from "assets/part-six/left-arrow.svg";
import "./index.scss";
import QuestionItem from "./components/question-item";

const numberOfQOnMobile = 5;
const PartSeven = () => {
  const deviceState = useSelector((store) => store.deviceState);
  const text = useSelector((store) => store.gd.translationsArr);

  const faqArr = useSelector((store) => store.gd.faqArr);

  const qa_1 = convertToSortedArray(faqArr[1].itemsArr);
  const qa_2 = convertToSortedArray(faqArr[2].itemsArr);

  const isMobile = deviceState.notDesktop;
  const [selected, setSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [rest, setRest] = useState([]);
  const [short, setShort] = useState([]);

  useEffect(() => {
    const selectedList = selected !== 0 ? [...qa_1] : [...qa_2];

    if (selectedList.length > numberOfQOnMobile) {
      setShort(selectedList.slice(0, numberOfQOnMobile));
      setRest(selectedList.slice(numberOfQOnMobile, selectedList.length));
      setShowMoreBtn(true);
    } else {
      setShort(selectedList);
      setShowMoreBtn(false);
      setRest([]);

    }
  }, [selected]);

  function moreClickHandler() {
    setIsOpen((prevState) => {
      return !prevState;
    });
  }

  const handleSetSelected = (val) => {
    if (isMobile) {
      setSelected(val);
    }
  };

  const renderDesktopView = () => {
    return (
      <div className="section-wrapper">
        <div className="service-questions-wrapper">
          <div className={"seven-top-btn"}>
            <img className={"top-btn-img "} src={getFullMediaUrl(faqArr[1].image.path)} alt={""} />
            <div className={"top-btn-text"}>
              {faqArr[1].title}
            </div>
          </div>
          <div className={"content"}>
            {qa_1.map((question, index) => (
              <QuestionItem questionItem={question} key={index} />
            ))}
          </div>
        </div>
        <div className="fixing-questions-wrapper">
          <div className={"seven-top-btn"}>
            <img className={"top-btn-img "} src={getFullMediaUrl(faqArr[2].image.path)} alt={""} />
            <div className={"top-btn-text"}>
              {faqArr[2].title}
            </div>
          </div>
          <div className={"content"}>
            {qa_2.map((question, index) => (
              <QuestionItem questionItem={question} key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <>
        <div className={"seven-top-btns-wrapper"}>
          <div
            key={0}
            className={`seven-top-btn ${selected === 0 ? "selected" : ""}`}
            onClick={() => handleSetSelected(0)}
          >
            <img className={"top-btn-img"} src={getFullMediaUrl(faqArr[1].image.path)} alt={""} />
            <div className={"top-btn-text"}>
              {faqArr[1].title}
            </div>
          </div>
          <div
            key={1}
            className={`seven-top-btn ${selected === 1 ? "selected" : ""} `}
            onClick={() => handleSetSelected(1)}
          >
            <img className={"top-btn-img"} src={getFullMediaUrl(faqArr[2].image.path)} alt={""} />
            <div className={"top-btn-text services-text"}>
              {faqArr[2].title}
            </div>
          </div>
        </div>
        <div className={"questions-wrapper"}>
          {short.map((question, index) => {
            return <QuestionItem questionItem={question} key={index} />;
          })}
          {isOpen &&
            rest.length > 0 &&
            rest.map((question, index) => (
              <QuestionItem questionItem={question} key={index} />
            ))}
        </div>
        {showMoreBtn && (
          <div
            className={"all-questions-link-wrapper"}
            onClick={moreClickHandler}
          >
            <img className={"left-arrow-img"} src={leftArrow} alt={""} />
            <div className={"link-text"}>
              {isOpen
                ? text["service_centers_close"]
                : text["service_centers_show_all_qa"]}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="home-seven">
      <div className={"seven-title"}>{text["service_centers_qa_title"]}</div>
      {isMobile ? renderMobileView() : renderDesktopView()}
    </div>
  );
};

export default PartSeven;
