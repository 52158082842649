import React from "react";
import { Component } from "react";
import { connect } from "react-redux";

import Validate from "app/validation/validation";

//components
import AnimatedInput from "../../components/forms/animated_input/animated_input";
import AnimatedTextArea from "components/forms/animated_textarea";
import AutoComplete from "components/forms/autocomplete/new";
import Select from "components/forms/select/index";
import RadioButton from "components/forms/radio-button";

//actions
import Actions from "redux/actions";

//assets
import "./index.scss";
import Checkbox from "components/forms/checkbox";

import checkedImage from "assets/checkbox/checked-checkbox.svg";
import emptyImage from "assets/checkbox/empty-checkbox.svg";
import radioEmptyImage from "assets/radio-button/radio-unchecked.svg";
import radioCheckedImage from "assets/radio-button/radio-checked.svg";


class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_try: true,
      form: {
        full_name: {
          valid: false,
          rules: ["full_name", "not_empty"],
          errMsg: "",
        },
        phone: {
          valid: false,
          rules: ["phone", "not_empty"],
          errMsg: "",
        },
        email: {
          valid: false,
          rules: ["email", "not_empty"],
          errMsg: "",
        },
        message: {
          valid: false,
          rules: ["not_empty"],
          errMsg: "",
        },
        cities: {
          id: "",
          valid: false,
          rules: [this.validateAutocomplete],
          errMsg: "",
        },
        terms: {
          valid: false,
          rules: ["checkbox"],
          errMsg: "",
        },
        type: {
          valid: false,
          rules: ["required_select"],
          errMsg: "",
        },
        gender: {
          valid: false,
          rules: ["required_radio"],
          errMsg: "",
        },
      },
    };
    
  }

  handleInputChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    if (e.target.type == "checkbox") {
      val = e.target.checked;
    }
    let validationObj = Validate(val, this.state.form[name].rules);

    let new_state = { ...this.state };
    new_state.form[name].valid = validationObj.valid;
    new_state.form[name].errMsg = validationObj.msg;

    this.setState(new_state);
    this.props.updateForm({ [name]: val });
  };

  handleAutocompleteSelection = (payload) => {
    this.props.updateForm({ cities: payload });
  };

  handleSelectChange = (name, id) => {
    this.props.updateForm({ [name]: id });
  };

  handleRadioChange = (name, value) => {
    this.props.updateForm({ [name]: value });
  };

  validateAutocomplete = (val) => {
    return { valid: false, msg: "sdfsdf" };
  };

  onTimeIsUpHandler = () => {
    console.log("hii");
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let form_valid = true;
    let new_state = { ...this.state.form };
    let validationObj;

    for (let field in this.state.form) {
      validationObj = Validate(
        this.props.Form[field],
        this.state.form[field].rules
      );

      new_state[field].valid = validationObj.valid;
      new_state[field].errMsg = validationObj.msg;

      if (!validationObj.valid) {
        form_valid = false;
      }
    }

    this.setState({ form: new_state, first_try: false });

    if (form_valid) {
      console.log("submit");
    }
  };

  showError = (field) => {
    return !this.state.first_try && !this.state.form[field].valid;
  };

  render() {
    const Form = this.props.Form;

    return (
      <section className="contact-page-wrapper">
        <div className="form-wrapper">
          <form name="contact-form">
          
            <AnimatedInput
              className="field"
              value={Form.full_name}
              name={"full_name"}
              placeholder={"שם מלא"}
              autocomplete={false}
              onChange={this.handleInputChange}
              showError={this.showError("full_name")}
              errorMessage={this.state.form.full_name.errMsg}
            />
            <AnimatedInput
              className="field"
              value={Form.phone}
              name={"phone"}
              placeholder={"טלפון נייד"}
              autocomplete={false}
              onChange={this.handleInputChange}
              showError={this.showError("phone")}
              errorMessage={this.state.form.phone.errMsg}
            />
            <Select
              className="field"
              label="סוג פנייה"
              name="type"
              options={typesOfMessage}
              onChange={this.handleSelectChange}
              mobileNativeSelect={false}
              selectedId={Form.type}
              showError={this.showError("type")}
              errorMessage={this.state.form.type.errMsg}
            />
            <AnimatedTextArea
              className="field"
              name="message"
              value={Form.message}
              placeholder={"סיבת הפנייה"}
              onChange={this.handleInputChange}
              showError={this.showError("message")}
              errorMessage={this.state.form.message.errMsg}
            />
            <AutoComplete
              className="field"
              name="cities"
              placeholder={"בחר עיר"}
              onChange={this.handleInputChange}
              onSelect={this.handleAutocompleteSelection}
              options={CityOptions}
            />
            <Checkbox
              className="field"
              type="checkbox"
              name="terms"
              id="terms"
              label="תנאי שימוש"
              value={Form.terms}
              onChange={this.handleInputChange}
              checkedImage={checkedImage}
              emptyImage={emptyImage}
              showError={this.showError("terms")}
              errorMessage={this.state.form.terms.errMsg}
            />
            <RadioButton
              className="field"
              options={genders}
              name="gender"
              value={Form.gender}
              onChange={this.handleRadioChange}
              checkedImage={radioCheckedImage}
              emptyImage={radioEmptyImage}
              showError={this.showError("gender")}
              errorMessage={this.state.form.gender.errMsg}
            />

            <button
              type="submit"
              onClick={this.handleSubmit}
              className="submit-btn teal-button"
            >
              שלח
            </button>
          </form>
        </div>
      </section>
    );
  }
}

//connect to redux store
const mapStateToProps = (store) => {
  return {
    deviceState: store.deviceState,
    Form: store.contactForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (payload) => dispatch(Actions.updateContactForm(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(Contact);

const CityOptions = [
  { text: "תל אביב", id: 0, payload: { id: 41 } },
  { text: "הרצליה", id: 1, payload: { id: 47 } },
  { text: "יבנה", id: 2, payload: { id: 23 } },
  { text: "כפר סבא", id: 3, payload: { id: 12 } },
];

const typesOfMessage = [
  { text: "רגילה", id: 0 },
  { text: "דחופה", id: 1 },
  { text: "דחופה מאוד", id: 2 },
];

const genders = [
  { text: "זכר", id: "male" },
  { text: "נקבה", id: "female" },
  { text: "אחר", id: "other" },
];
