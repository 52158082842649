import React, {Component} from 'react';
import { connect }        from 'react-redux';

import './index.scss';

class Tool extends Component {

    render() {
        return (
            <div className="tool-wrapper">
                <div className="component">
                    <div className="component-title">MetaTags</div>
                    <div className="component-usage">
                        Renders dynamic meta tags from the server. Implemented in App.js.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">import MetaTags from 'components/meta_tags';</div>
                </div>
                <div className="import-strip">
                    <div className="label">Receives it's data from redux: store.data.MetaTags</div>
                </div>
                <div className="code-sample">
                    <pre>
{`{
    meta_tags: {
        description: "תחנות דלק TEN, שירות מצוין, דלקים איכותיים ומחירים תחרותיים.",
        keywords: "דלק, ten, תחנת דלק",
        title: "tenwebsite.inmanage.com - תחנות דלק TEN | מבצעי הדלק הטובים בארץ"
    },
    extra_tags: [
        <meta http-equiv='content-type' content='text/html; charset=utf-8' />
        <meta http-equiv='imagetoolbar' content='yes' />
        <meta name='robots' content='index, follow' />
    ]
}`}
                    </pre>
                </div>
                <div className="code-sample">
                    <code>
                        {`<MetaTags />`}
                    </code>
                </div>
                <div className="note">
                    <div className="note-label">note:</div>
                    <span>Extra tag items are rendered using ReactHtmlParser</span>
                </div>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {}
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Tool);
