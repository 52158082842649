import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import ButtonBasic from "components/common/buttons/button_basic";

import Api from "api/requests";
import { getFullMediaUrl, convertToSortedArray } from "app/functions";
import Actions from "redux/actions";
import * as popups from "constants/popup-types";

import "./index.scss";
import { googleAnalyticsManager } from "app/GoogleAnalytics/GoogleAnalyticsManager";

const PartFive = () => {
  const dispatch = useDispatch();
  const iconsArr = useSelector(
    (store) => store.gd.icons_groupsArr.insurance_agents_iconsArr
  );
  const deviceState = useSelector((store) => store.deviceState);
  const imagesArr = convertToSortedArray(iconsArr);
  const text = useSelector((store) => store.gd.translationsArr);

  const genralContent = useSelector(
    (store) => store.gd.general_content_imagesArr
  );
  const part5Content = genralContent["part5_content"];
  const mobilePhoto = getFullMediaUrl(part5Content?.imageArr?.mobile_path);
  const desktopPhoto = getFullMediaUrl(part5Content?.imageArr?.path);
  const title = part5Content.title;
  const content = part5Content.content;

  function openPopup(text = "") {
    googleAnalyticsManager.clickOnButton(text);
    dispatch(
      Actions.addPopup({ type: popups.Agent, payload: { sendForm: sendForm } })
    );
  }

  function sendForm(agentForm) {
    Api.sendInsuranceForm({
      payload: {
        name: agentForm.fullname,
        role: agentForm.role,
        phone: agentForm.phone,
        email: agentForm.email,
      },
    });
  }

  return (
    <div className="home-five">
      <img
        className={"five-bg"}
        src={deviceState.isDesktop ? desktopPhoto : mobilePhoto}
        alt={""}
      />
      <div className={"five-content-wrapper"}>
        <div className={"five-title"}>{title}</div>
        <div className={"five-text"}>{content}</div>
        <div className={"five-attributes-wrapper"}>
          {imagesArr.map((item, i) => {
            return (
              <div className={"five-attribute"} key={i}>
                <img src={getFullMediaUrl(item?.imageArr?.path)} alt={""} />
                <div className={"five-attribute-text"}>{item.title} </div>
              </div>
            );
          })}
        </div>
        <ButtonBasic
          className={"btn contact-btn"}
          btnText={text["service_centers_get_in_contact"]}
          onClick={() => {
            openPopup(text["service_centers_get_in_contact"]);
          }}
        />
      </div>
    </div>
  );
};

export default PartFive;
