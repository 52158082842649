import React, { Component } from 'react';
import {connect}            from 'react-redux';
import './index.scss';

class ContentPage extends Component {

    render() {

        var Text = this.props.Translations;

        return (
            <div className="content-page">
                <h1 className="title">content page</h1>
            </div>
        )
    }
}

//connect this component to the redux store
const mapStateToProps = store => {
    return {
        Translations: store.config.Translations,
        deviceState: store.deviceState,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);