import React, { Component } from 'react';
import './index.scss';

class Collapse extends Component {

    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }
    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        let { open = false } = this.props;
        let style = {};
        if(open && this.contentRef.current !== null) {
            let height   = this.contentRef.current.clientHeight;
            style.height = height;
        }

        return (
            <div className="collapse-wrapper" style = { style }>
                <div className="collapse-content" ref = { this.contentRef }>
                    { this.props.children }
                </div>
            </div>
        )
    }
}

export default Collapse;