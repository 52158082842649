import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let dataReducers = {};
let dataActions = {};

export const gdSlice = createSlice({
  name: "gd",
  initialState: false,
  reducers: {
    setGd: (state, action) => action.payload,
  },
});
Slices.push(gdSlice);

/*---------------------------------------------------------------*/

export const userSlice = createSlice({
  name: "userData",
  initialState: false,
  reducers: {
    setUser: (state, action) => action.payload,
  },
});

// Action creators are generated for each case reducer function
Slices.push(userSlice);

/*---------------------------------------------------------------*/

export const metaTagsSlice = createSlice({
  name: "metaTags",
  initialState: false,
  reducers: {
    setMetaTags: (state, action) => action.payload,
  },
});

/*---------------------------------------------------------------*/
Slices.push(metaTagsSlice);

/*---------------------------------------------------------------*/

export const streetsSlice = createSlice({
  name: "streets",
  initialState: false,
  reducers: {
    setStreets: (state, action) => action.payload,
  },
});
Slices.push(streetsSlice);

/*---------------------------------------------------------------*/

export const ModelsAndManufactersSlice = createSlice({
  name: "ModelsAndManufacters",
  initialState: { manufacturers: [], models: [] },
  reducers: {
    setModelsAndManufacters: (state, action) => {
      const modelsAndManufacter = action.payload;
      const models = [];
      const manufacturers = [];
      for (const key in modelsAndManufacter) {
        const manufacturer = modelsAndManufacter[key];
        const modelsArr = manufacturer.modelsArr;
        manufacturers.push({ id: key, text: manufacturer.title });
        for (const modelKey in modelsArr) {
          const model = modelsArr[modelKey];
          models.push({
            manufacturerId: key,
            manufacturerName: manufacturer.title,
            id: modelKey,
            text: model.title,
          });
        }
      }
      return { manufacturers, models };
    },
  },
});
// Action creators are generated for each case reducer function
Slices.push(ModelsAndManufactersSlice);

//build export objects
for (const Slice of Slices) {
  dataActions = { ...dataActions, ...Slice.actions };
  let reducer = { [Slice.name]: Slice.reducer };
  dataReducers = { ...dataReducers, ...reducer };
}

export { dataActions };
export { dataReducers };
