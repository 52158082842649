import { createSlice } from '@reduxjs/toolkit';

let Slices = [];
let stateReducers = {};
let stateActions  = {};

export const deviceSlice = createSlice({
    name: 'deviceState',
    initialState: false,
    reducers: {
        setDeviceState: (state, action) => action.payload,
    },
})

// Action creators are generated for each case reducer function
Slices.push(deviceSlice);

/*---------------------------------------------------------------*/

export const loaderSlice = createSlice({
    name: 'loaderState',
    initialState: false,
    reducers: {
        setLoader: (state, action) => action.payload
    },
});

// Action creators are generated for each case reducer function
Slices.push(loaderSlice);

/*---------------------------------------------------------------*/

export const popupsSlice = createSlice({
    name: 'popupsArray',
    initialState: [],
    reducers: {
        addPopup: (state, action) => {
            state = state.push(action.payload)
        },
        removePopup: (state) => {
            state = state.pop();
        },
    },
})

// Action creators are generated for each case reducer function
Slices.push(popupsSlice);

/*---------------------------------------------------------------*/

export const requestingSlice = createSlice({
    name: 'requestingState',
    initialState: false,
    reducers: {
        requestStarted: (state, action) => true,
        requestEnded: (state, action) => false,
    },
});

// Action creators are generated for each case reducer function
Slices.push(requestingSlice);

/*---------------------------------------------------------------*/

export const burgerSlice = createSlice({
    name: 'burgerState',
    initialState: false,
    reducers: {
        setBurger: (state, action) => action.payload,
    },
});

// Action creators are generated for each case reducer function
Slices.push(burgerSlice);

/*---------------------------------------------------------------*/
export const backdropSlice = createSlice({
  name: "backdropState",
  initialState: false,
  reducers: {
    setBackdrop: (state, action) => action.payload,
  },
});

// Action creators are generated for each case reducer function
Slices.push(backdropSlice);

/*---------------------------------------------------------------*/
for(const Slice of Slices) {
    stateActions  = {...stateActions, ...Slice.actions};
    let reducer   = {[Slice.name]:Slice.reducer};
    stateReducers = {...stateReducers, ...reducer};
}

export { stateActions };
export { stateReducers };