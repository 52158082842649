import React, { useEffect } from "react";
import "./index.scss";


const Loader = (props) => {
  return (
    <div className="loader_wrapper" id = "loader-wrapper">
      <div className="loader"></div>
    </div>
  );
};
export default Loader;
