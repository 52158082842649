import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as routes from "constants/routes";

import Actions from "redux/actions";
import Validate from "app/validation/validation";
import CustomInput from "components/forms/custom-input/CustomInput";

import "./AgentPopup.scss";
import Close from "assets/icons/close.svg";

function AgentPopup(props) {
  const [animationClass, setAminationClass] = useState("");
  const text = useSelector((store) => store.gd.translationsArr);
  const [firstTry, setFirstTry] = useState(true);
  const [isFormVaild, setIsFormVaild] = useState(true);
  const deviceState = useSelector((store) => store.deviceState);

  const agentForm = useSelector((store) => store.agentForm);
  const dispatch = useDispatch();

  const [formValidate, setFormValidate] = useState({
    fullname: {
      valid: true,
      rules: ["not_empty", "full_name"],
    },
    role: {
      valid: true,
      rules: ["not_empty"],
    },
    phone: {
      valid: true,
      rules: ["not_empty", "phone"],
    },
    email: {
      valid: true,
      rules: ["not_empty", "email"],
    },
  });

  function handleSubmit(e) {
    e.preventDefault();

    let form_valid = true;
    let new_state = { ...formValidate };
    let validationObj;

    for (let field in formValidate) {
      validationObj = Validate(agentForm[field], formValidate[field].rules);

      new_state[field].valid = validationObj.valid;

      if (!validationObj.valid) {
        form_valid = false;
      }
    }

    setFormValidate(new_state);
    setFirstTry(false);
    setIsFormVaild(form_valid);

    if (form_valid) {
      console.log(agentForm);
      animateOut(() => props.payload.sendForm(agentForm));
     
    }
  }

  function isValid(field) {
    return firstTry || formValidate[field].valid;
  }

  function handleChange(field, value) {
    let validationObj = Validate(value, formValidate[field].rules);

    let new_state = { ...formValidate };
    new_state[field].valid = validationObj.valid;
    setFormValidate(new_state);
    dispatch(Actions.updateAgentForm({ [field]: value }));
  }

  const animateIn = () => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setAminationClass("active");
      });
    });
  };
  useEffect(() => {
    animateIn();
  }, []);

  const completeAnimation = () => {
    if (animationClass !== "exit" && animationClass !== "done") {
      setAminationClass("done");
    }
  };
  function animateOut(callback) {
    setAminationClass("exit");

    setTimeout(() => {
      dispatch(Actions.removePopup());
      if (callback) {
        callback();
      }
    }, 200);
  }

  return (
    <div
      className={"backdrop general-msg agent-popup-wrapper " + animationClass}
      onClick={() => animateOut()}
      onTransitionEnd={completeAnimation}
    >
      <div
        className={"popup_wrapper " + animationClass}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="close-icon-wrapper" onClick={() => animateOut()}>
          <img src={Close}></img>
        </div>
        <div className="popup_content ">
          <div className="popup-head">
            <h3 className="title">
              {text["service_centers_agent_popup_title"]}
            </h3>
            {deviceState.isDesktop && <span>&nbsp;</span>}
            <h3 className="title">
              {text["service_centers_agent_popup_subtitle"]}
            </h3>
          </div>
          <div className="popup-form">
            <CustomInput
              className="field"
              label={text["service_centers_full_name"]}
              value={agentForm.fullname}
              name="fullname"
              isValid={isValid("fullname")}
              onChange={handleChange}
              type="text"
            />
            <CustomInput
              className="field"
              label={text["service_centers_role"]}
              value={agentForm.role}
              name="role"
              isValid={isValid("role")}
              onChange={handleChange}
              type="text"
            />
            <CustomInput
              className="field"
              label={text["service_centers_phone"]}
              value={agentForm.phone}
              name="phone"
              isValid={isValid("phone")}
              onChange={handleChange}
              type="number"
            />
            <CustomInput
              className="field"
              label={text["service_centers_email"]}
              value={agentForm.email}
              name="email"
              isValid={isValid("email")}
              onChange={handleChange}
              type="text"
            />

            <span className={`error-text-form ${!isFormVaild ? "show" : ""}`}>
              {text["service_centers_treatment_form_error"]}
            </span>

            <button type="submit" className="btn-submit" onClick={handleSubmit}>
              {text["service_centers_send"]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentPopup;
