import React, { Component } from "react";
import { connect } from "react-redux";

import "./index.scss";
import PartOne from "./parts/part-one";
import PartTwo from "./parts/part-two";
import PartThree from "./parts/part-three";
import PartFour from "./parts/part-four";
import PartFive from "./parts/part-five";
import PartSix from "./parts/part-six";
import PartSeven from "./parts/part-seven";
import PartEight from "./parts/part-eight";

class Home extends Component {
  render() {
    const Text = this.props.Gd.translationsArr
      ? this.props.Gd.translationsArr
      : "text";
    return (
      <div className="home">
        <PartOne />
        <PartTwo />
        <PartThree />

        <PartEight />

        <PartFour />
        <PartFive />

        <PartSix />
        <PartSeven />
      </div>
    );
  }
}

//add GD categories and deviceState to props
const mapStateToProps = (store) => {
  return {
    deviceState: store.deviceState,
    Gd: store.gd,
  };
};
//map a doLgin function to props
const mapDispatchToProps = (dispatch) => {
  return {};
};

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false,
})(Home);
