import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import CustomInput from "components/forms/custom-input/CustomInput";
import CustomDatePicker from "components/forms/custom-datepicker";
import CustomAutoComplete from "components/forms/custom-autocomplete/CustomAutoComplete";

import Api from "api/requests";
import Actions from "redux/actions";

import "./CarDetails.scss";
import Pen from "assets/icons/pen.svg";
import Calendar from "assets/icons/calendar.svg";
import Check from "assets/icons/check.svg";
import Loader from "assets/gif/loader.gif";

function CarDetails(props) {
  const { isValidFunction, handleChange, isLicenseEntred, setIsLicenseEntred, firstTry } = props;
  const treatmentForm = useSelector((store) => store.treatmentForm);
  const text = useSelector((store) => store.gd.translationsArr);
  const allModels = useSelector((store) => store.ModelsAndManufacters.models);
  const manufacturers = useSelector(
    (store) => store.ModelsAndManufacters.manufacturers
  );
  const [isLicenseValidate, setIsLicenseValidate] = useState(false);
  const [models, seModels] = useState([]);
  const [isManufacturerSelected, setIsManufacturerSelected] = useState(false);
  const [isLoaded, setIsLoded] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isLicensedVaildFromGov, setIsLicensedVaildFromGov] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLicensedVaildFromGov) {
      setIsManufacturerSelected(
        treatmentForm.manufacturer !== -1 &&
          treatmentForm.manufacturer !== undefined
      );
      if (allModels) {
        const newModelsArray = allModels.filter(function (model) {
          return model.manufacturerId === treatmentForm.manufacturer;
        });
        seModels(newModelsArray);
      }
      handleChange("model", -1);
    }
  }, [treatmentForm.manufacturer]);

  useEffect(() => {
    if (!isLicensedVaildFromGov) {
      let selected = models.filter(function (model) {
        return model.id === treatmentForm.model;
      });
      if (selected && selected.length > 0) {
        onModelUpdate(selected[0]);
      }
    }
  }, [treatmentForm.model]);

  function onModelUpdate(selected) {
    dispatch(
      Actions.updateTreatmentForm({ manufacturer: selected.manufacturerId })
    );
    dispatch(
      Actions.updateTreatmentForm({
        manufacturerName: selected.manufacturerName,
      })
    );
    dispatch(
      Actions.updateTreatmentForm({
        model: selected.id,
      })
    );
    dispatch(
      Actions.updateTreatmentForm({
        modelName: selected.text,
      })
    );
  }
  function onBtnLicenseClickFinishedHandler() {
    setIsLicenseValidate(true);
  }
  function onBtnLicenseClickHandler() {
    function onLicenseVaild(response) {
      setIsLicensedVaildFromGov(true);
      handleChange("manufacturer", response.manufacturer_name);
      handleChange("model", response.model_name);
      onModelUpdate({
        manufacturerId: response.manufacturer_code,
        manufacturerName: response.manufacturer_name,
        id: response.model_code,
        text: response.model_name,
      });
    }
    function onLicenseNotValid() {
      setIsLicensedVaildFromGov(false);
    }
    if (treatmentForm.license) {
      setisLoading(true);
      setIsLicenseEntred(true);
      Api.searchGovCar({
        payload: { car_number: treatmentForm.license },
        onLicenseVaild: onLicenseVaild,
        onLicenseNotValid: onLicenseNotValid,
      }).then(() => {
        setisLoading(false);
        setIsLoded(true);
        onBtnLicenseClickFinishedHandler();
      });
    } else {
      setIsLicenseEntred(false);
    }
  }
  function onPenClick() {
    setIsLicensedVaildFromGov(false);
  }
  function getSendButton() {
    if (isLoading)
      return (
        <div className="loader-wrapper">
          <img src={Loader} />
        </div>
      );
    else {
      if (isLoaded) {
        return (
          <div className="checked-wrapper">
            <img src={Check} />
          </div>
        );
      } else {
        return (
          <button className="btn-send" onClick={onBtnLicenseClickHandler}>
            {text["service_centers_send"]}
          </button>
        );
      }
    }
  }
  function getPen() {
    return (
      <div className="pen-wrapper" onClick={onPenClick}>
        <img src={Pen} />
      </div>
    );
  }

  function getDatePlusDays(numberOfDays) {
    const today = new Date();
    let newDate = new Date();

    newDate.setDate(today.getDate() + numberOfDays);
    return newDate;
  }

  return (
    <div className="car-details-wrapper">
      <h3 className="title">{text["service_centers_car_details_title"]}</h3>
      <div className="car-details-inputs">
        <CustomInput
          className="field"
          label={text["service_centers_license_num"]}
          value={treatmentForm.license}
          name="license"
          isValid={firstTry || (isLicenseEntred && isValidFunction("license")) }
          onChange={handleChange}
          type="number"
          btnFunction={getSendButton}
          isNegativeAllowd={false}
        />
        {isLicenseValidate && !isLicensedVaildFromGov && (
          <div className="selects-wrapper">
            <CustomAutoComplete
              className="field"
              options={manufacturers}
              placeholder={text["service_centers_manufacturers"]}
              name="manufacturer"
              isValid={isValidFunction("manufacturer")}
              onSelected={handleChange}
              selectedId={treatmentForm.manufacturer}
              disabled={false}
              dropDownImg={Pen}
            />
            <CustomAutoComplete
              className="field"
              options={models}
              placeholder={text["service_centers_model"]}
              name="model"
              isValid={isValidFunction("model")}
              onSelected={handleChange}
              selectedId={treatmentForm.model}
              disabled={!isManufacturerSelected}
              dropDownImg={Pen}
            />
          </div>
        )}

        {isLicenseValidate && isLicensedVaildFromGov && (
          <div className="selects-wrapper">
            <CustomInput
              className="field"
              label={text["service_centers_manufacturers"]}
              value={treatmentForm.manufacturerName}
              name="manufacturer"
              isValid={isValidFunction("manufacturer")}
              onChange={handleChange}
              isDisabled={true}
              btnFunction={getPen}
            />
            <CustomInput
              className="field"
              label={text["service_centers_model"]}
              value={treatmentForm.modelName}
              name="model"
              isValid={isValidFunction("model")}
              onChange={handleChange}
              isDisabled={true}
              btnFunction={getPen}
            />
          </div>
        )}

        <CustomInput
          className="field"
          label={text["service_centers_type_km"]}
          value={treatmentForm.km}
          name="km"
          isValid={isValidFunction("km")}
          needToShowBtn={false}
          onChange={handleChange}
          type="number"
          extraText={text["service_centers_km"]}
          isNegativeAllowd={false}
          maxLength={10}
        />
        <CustomDatePicker
          className="field"
          date={treatmentForm.date}
          minDate={getDatePlusDays(2)}
          name="date"
          setDate={handleChange}
          icon={Calendar}
          label={text["service_centers_choose_date"]}
          isValid={isValidFunction("date")}
          extraText={text["service_centers_choosen_date"]}
        />
      </div>
    </div>
  );
}

export default CarDetails;
