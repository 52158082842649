import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as routes from "constants/routes";

import Actions from "redux/actions";

import "./iframePopup.scss";
import Close from "assets/icons/close.svg";


function IframePopup(props) {
  const [animationClass, setAminationClass] = useState("");
  const deviceState = useSelector((store) => store.deviceState);
  const dispatch = useDispatch();

  const animateIn = () => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setAminationClass("active");
      });
    });
  };
  useEffect(() => {
    animateIn();
  }, []);

  const completeAnimation = () => {
    if (animationClass !== "exit" && animationClass !== "done") {
      setAminationClass("done");
    }
  };
  const animateOut = (callback) => {
    setAminationClass("exit");
    setTimeout(() => {
      if (callback) {
        callback();
      }
      dispatch(Actions.removePopup());
    }, 200);
  };

  return (
    <div
      className={"backdrop general-msg iframe-popup-wrapper " + animationClass}
      onClick={() => animateOut()}
      onTransitionEnd={completeAnimation}
    >
      <div
        className={"popup_wrapper " + animationClass}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="close-icon-wrapper" onClick={() => animateOut()}>
          <img src={Close}></img>
        </div>
        <div className="popup_content ">
                <iframe className ="iframe" src ={props.payload.src}/>
        </div>
      </div>
    </div>
  );
}

export default IframePopup;
