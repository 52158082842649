import React, {Component} from 'react';
import { connect }        from 'react-redux';

import './index.scss';

class Tool extends Component {

    render() {
        
        return (
            <div className="tool-wrapper">
                <div className="component">
                    <div className="component-title">
                        deviceState
                    </div>
                    <div className="component-usage">
                        Render components or implement different behaviour per device type.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">redux: store.deviceState</div>
                </div>
                <div className="code-sample">
                    <pre>
{`{
    device: "mobile"
    isDesktop: false
    isMobile: true
    isTablet: false
    notDesktop: true
    notMobile: false
}
`}
                    </pre>
                </div>
                <div className="note">
                    <div className="note-label">note:</div>
                    <span>Use only if a responsive solution is impossible or too time consuming.</span>
                </div>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {}
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Tool);
