import React, { useRef } from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

import { getFullMediaUrl, convertToSortedArray } from "app/functions";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import leftArrow from "assets/part-six/left-arrow.svg";
import rightArrow from "assets/part-six/right-arrow.svg";
import leftArrowDesktop from "assets/part-six/left-arrow-desktop.svg";
import rightArrowDesktop from "assets/part-six/right-arrow-desktop.svg";
import "./index.scss";
import Recommendation from "./recommendation/recommendation";

SwiperCore.use([Navigation, Autoplay]);

const PartSix = () => {
  const deviceState = useSelector((store) => store.deviceState);

  const text = useSelector((store) => store.gd.translationsArr);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  let customersFeedbackArr = useSelector(
    (store) => store.gd.customers_feedbackArr
  );

  customersFeedbackArr = convertToSortedArray(customersFeedbackArr);

  const arrows = {
    prevEl: navigationPrevRef.current,
    nextEl: navigationNextRef.current,
  };
  const swiperSettings = {
    navigation: arrows,
    slidesPerView: "1",
    loop: true,
    autoplay: {
      delay: 4000,
    },
  };

  function getSlides() {
    return customersFeedbackArr.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div className="slide-wrapper">
            <Recommendation
              text={item.content}
              name={item.title}
              subtitle={item.subtitle}
              photo={
                item?.imageArr?.path !== ""
                  ? getFullMediaUrl(item?.imageArr?.path)
                  : undefined
              }
            />
          </div>
        </SwiperSlide>
      );
    });
  }

  return (
    <div className={"home-six"}>
      <div className={"six-title"}>{text["service_centers_part6_tilte"]}</div>
      <Swiper className="slider" {...swiperSettings}>
        <div className={"prev-arrow-wrapper "}>
          <img
            className="prev-arrow"
            src={deviceState.isDesktop ? rightArrowDesktop : rightArrow}
            ref={navigationPrevRef}
          />
        </div>
        <div className={"next-arrow-wrapper "}>
          <img
            className="next-arrow"
            src={deviceState.isDesktop ? leftArrowDesktop : leftArrow}
            ref={navigationNextRef}
          />
        </div>

        {getSlides()}
      </Swiper>
    </div>
  );
};

export default PartSix;
