import React         from 'react';
import { Component } from 'react';
import { connect }   from 'react-redux';
import { Switch }    from 'react-router-dom';
import { Route }     from 'react-router-dom';
import { Redirect }  from 'react-router-dom';
import { NavLink }   from 'react-router-dom';

//Import documentation
import Tools      from './tools';
import Components from './components';

//css
import './index.scss';

//routing constants
import * as Routes    from './doc-routes';
import * as TopRoutes from 'constants/routes';


class Documentation extends Component {
    render() {
        return (
            <div className="documentation-wrapper">
                <div className="menu-wrapper">
                    <NavLink to = { Routes.tools } activeClassName='active' className="docs-category-item">Tools</NavLink>
                    <NavLink to = { Routes.components } activeClassName='active' className="docs-category-item">Components</NavLink>
                </div>
                <Switch>
                    <Route exact path={ TopRoutes.docs }>
                        <Redirect to={ Routes.tools } />
                    </Route>
                    <Route path={ Routes.tools } component={ Tools }/>
                    <Route path={ Routes.components } component={ Components }/>
                </Switch>
            </div>
        )
    }
}

//get required state from redux store and map them to the components props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
    }
}
export default connect(mapStateToProps, null, null, { pure: false })(Documentation);
