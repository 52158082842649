import React, { Component }     from 'react';
import {generateUniqueId}       from "../../../app/functions"
import {useState}               from "react"

import './animated_input.scss'

/**
 *
 ## Animated input
 ## Input with animated place holder
 ##    parameters:
 ###      showError    - true / false, true = showing the error message
 ###      errorMessage - If input is wrong, show this text message
 ###      placeholder  - the animated string inside the input
 ###      onChange     - Needed to change the value
 ###      className    - Adding new class
 ###      autocomplete - true / false
 ###      value        - input value
 ###      name         - input name
 ###      type         - input type

**/


function AnimatedInput(props){

    /*
        Props
    */
    const {
        id = generateUniqueId(16),
        autocomplete = true,
        placeholder = '',
        tabIndex = 10,
        errorMessage,
        value = '',
        className,
        showError,
        type,
        name,

    } = props;


    /*
        Text stay animated when input is not undefined
    */
    const animatedPlaceholder = (e) => {
        let val = e.target.value;
        props.onChange(e);
    }

    let is_animated = value !== '' ? true : false;

    return(
        // Input wrapper
        <div className = {'animated-input-wrapper ' + className}>
            {/* Input */}
            <input
                    onChange        =   {(e) => animatedPlaceholder(e)}
                    autoComplete    =   {autocomplete ? '' : 'off'}
                    tabIndex        =   {tabIndex}
                    className       =   {'input'}
                    value           =   {value}
                    type            =   {type}
                    name            =   {name}
                    id              =   {id}
            />
            {/* Placeholder */}
            <label htmlFor={id} className={'placeholder ' + (!is_animated ? '' : 'animated')}>
                {placeholder}
            </label>

            {/* Error message */}
            {
                showError &&
                <span className={'error-text'}>
                    {errorMessage}
                </span>
            }

        </div>
    );

}

export default AnimatedInput;