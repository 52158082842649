import React, { useState } from "react";

import "./CustomInput.scss";

function CustomInput(props) {
  const {
    label = "",
    value = "",
    isValid = true,
    name = "",
    onChange,
    type = "text",
    className = "",
    extraText = "",
    btnFunction,
    isDisabled = false,
    isBtnClickable = true,
    isNegativeAllowd = true,
    maxLength = undefined,
  } = props;

  const [placeholder, setPlaceholder] = useState(label);

  function onFocus() {
    setPlaceholder("");
  }

  function onFocusOut() {
    setPlaceholder(label);
  }

  function onChangeHandler(e) {
    const field = e.target.name;
    let value = e.target.value;
    if (maxLength) {
      value = value.substring(0, maxLength);
    }
    onChange(field, value);
  }
  // function onKeyUp(e) {
  //   if (extraText !== undefined && extraText !== "") {
  //     e.target.style.width = value.length * 0.7 + "em";
  //     if (value.length === 0) {
  //       e.target.style.width = "50%";
  //     }
  //   }
  // }
  function onKeyDown(event) {
    if (!isNegativeAllowd) {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          break;

        case "ArrowUp":
          event.preventDefault();
          break;
        case "-":
          event.preventDefault();
          break;
      }
    }
  }

  return (
    <div
      className={`custom-input-wrapper ${
        isValid ? "" : "unvalid"
      } ${className} ${isDisabled ? "disabled" : ""}`}
    >
      <input
        className="input"
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
        name={name}
        type={type}
        pattern={type == "number" ? "\\d*" : ".{0,}"}
        disabled={isDisabled}
        onKeyDown={onKeyDown}
        min={type == "number" ? "0" : ""}
        onFocus={onFocus}
        onBlur={onFocusOut}
        maxLength={maxLength ? maxLength : ""}
      />
      {value !== "" && extraText && (
        <label className="extra-text">{extraText}</label>
      )}
      {btnFunction && (
        <div className={`btn-wrapper ${isBtnClickable ? "active" : ""} `}>
          {btnFunction()}
        </div>
      )}
    </div>
  );
}

export default CustomInput;
