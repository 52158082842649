import { useSelector } from "react-redux";

import "./recommendation.scss";
import person from "assets/part-six/person.png";
import quotes from "assets/part-six/quotes.svg";

function Recommendation(props) {
  const deviceState = useSelector((store) => store.deviceState);

  const { text, photo, name, subtitle } = props;

  return (
    <div className="recommendation-wrapper">
      <div className={"text"}>
        {deviceState.isDesktop && (
          <div className="image-wrapper start">
            <img src={quotes} />
          </div>
        )}
        {text}
        {deviceState.isDesktop && (
          <div className="image-wrapper end">
            <img src={quotes} />
          </div>
        )}
      </div>
      <div className={"customer-wrapper"}>
        {photo && <img className={"person"} src={photo} alt={""} />}
        <div className={"name"}>{name}</div>
        <div className={"subtitle"}>{subtitle}</div>
      </div>
    </div>
  );
}
export default Recommendation;
