import React from "react";

import { useSelector } from "react-redux";
import { getFullMediaUrl,  } from "app/functions";

import ImageTexCard from "./ImageTextCard/index.js";

function PartEight(props) {
  const genralContent = useSelector(
    (store) => store.gd.general_content_imagesArr
  );

  const part8Content1 = genralContent["part8_content_1"];
  const part8Content2 = genralContent["part8_content_2"];
 

  const deviceState = useSelector((store) => store.deviceState);
  const data = [
    {
      title: part8Content1.title,
      subtitle: part8Content1.subtitle,
      text: part8Content1.content,
      image: deviceState.isDesktop
        ? getFullMediaUrl(part8Content1?.imageArr?.path)
        : getFullMediaUrl(part8Content1?.imageArr?.mobile_path),
      dir: "rtl",
      id: 0,
    },
    {
      title: part8Content2.title,
      text: part8Content2.content,
      image: deviceState.isDesktop
        ? getFullMediaUrl(part8Content2?.imageArr?.path)
        : getFullMediaUrl(part8Content2?.imageArr?.mobile_path),
      dir: "ltr",
      id: 1,
    },
  ];

  return (
    <div className="part-eight-wrapper">
      {data.map((item) => {
        return <ImageTexCard {...item} key={item.id} />;
      })}
    </div>
  );
}

export default PartEight;
