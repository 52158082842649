import Actions from 'redux/actions';

export default class deviceState {

    constructor(dispatch) {
        this.dispatch            = dispatch;                      //redux action dispatch function
        this.indicator           = document.createElement('div'); //div element to use for measurement
        this.indicator.className = 'state-indicator';             //div class
        this.debounce_delay      = 250;                           //debounce delay in milliseconds
        document.body.appendChild(this.indicator);
        this.start();
    };

    //returns the current device state according to the width of the screen as measured by
    //the state-indicator div.
    getScreen = () => {
        var index = parseInt(window.getComputedStyle(this.indicator).getPropertyValue('z-index'), 10);
        var states = {
            1: 'desktop-large',
            2: 'desktop',
            3: 'tablet',
            4: 'mobile'
        };
        return states[index] || 'desktop';
    };

    start = () => {
        //Listen for screen resize with a debounce
        var response = this.debounce(() => {

            //determine current device
            let device = this.getScreen();

            //set device type in the redux store
            let payload = {
                device: device,
                isMobile: device === 'mobile' ? true : false,
                isTablet: device === 'tablet' ? true : false,
                isDesktop: (device === 'desktop' || device === 'desktop-large') ? true : false,
                notDesktop: (device !== 'desktop' && device !== 'desktop-large') ? true : false,
                notMobile: device !== 'mobile' ? true : false,
            }
            this.dispatch(Actions.setDeviceState(payload));

        }, this.debounce_delay, false);

        window.addEventListener('resize', response);
        response();
    }

    //Debounce the device state function so that it is called only once every 250ms
    debounce(func, wait, immediate) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate)
                    func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow)
                func.apply(context, args);
        };
    }
}