import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { getFullMediaUrl } from "app/functions";
import "./index.scss";

const PartThree = () => {
  const genralContent = useSelector(
    (store) => store.gd.general_content_imagesArr
  );
  const part3Content = genralContent["part3_content"];
  const ribbon = genralContent["ribbonImg"];
  const mobilePhoto = getFullMediaUrl(part3Content?.imageArr?.mobile_path);
  const desktopPhoto = getFullMediaUrl(part3Content?.imageArr?.path);
  const title = part3Content.title;
  const content = part3Content.content;
  const topIcon = getFullMediaUrl(ribbon?.imageArr?.path);

  const deviceState = useSelector((store) => store.deviceState);

  return (
    <div className="home-three">
      <div className={"home-three-backaground"}>
        <img
          src={deviceState.isDesktop ? desktopPhoto : mobilePhoto}
          alt={""}
        />
      </div>
      <div className="home-three-conetnt">
        <div className="ribbon-image-wrapper">
          <img className={"three-top-icon"} src={topIcon} alt={""} />
        </div>
        <div className="text-content">
          <div className={"three-title"}>{title}</div>
          <div className={"three-content"}>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default PartThree;
