import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "redux/actions";
import * as routes from "constants/routes";


import "./treatmentPopup.scss";
import Close from "assets/icons/close.svg";
import Plane from "assets/icons/paper-plane.svg";


function TreatmentPopup(props) {
  const [animationClass, setAminationClass] = useState("");
  const text = useSelector((store) => store.gd.translationsArr);

  const disptch = useDispatch();
  const animateIn = () => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setAminationClass("active");
      });
    });
  };
  useEffect(() => {
    animateIn();
  }, []);

  const completeAnimation = () => {
    if (animationClass !== "exit" && animationClass !== "done") {
      setAminationClass("done");
    }
  };
  const animateOut = () => {
    setAminationClass("exit");

    setTimeout(() => {
      if (props.payload.callback) {
        props.payload.callback();
      }
      disptch(Actions.removePopup());
    }, 200);
  };

  return (
    <div
      className={
        "backdrop general-msg treatment-popup-wrapper " + animationClass
      }
      onClick={animateOut}
      onTransitionEnd={completeAnimation}
    >
      <div
        className={"popup_wrapper " + animationClass}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="close-icon-wrapper" onClick={animateOut}>
          <img src={Close}></img>
        </div>

        <div className="popup_content ">
          <div className="image-wrapper">
            <img src={Plane}></img>
          </div>
          <h3 className="popup-title">
            {text["service_centers_treatment_popup_title"]}
          </h3>
          <p className="popup-subtitle">
            {text["service_centers_treatment_popup_subtitle"]}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TreatmentPopup;
