import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DynamicLink from "components/dynamic_link";

//import actions
import Actions from "redux/actions";
import close from "assets/icons/close-window-icon.svg";
import "./index.scss";

export default function BurgerMenu(props) {
  const menuItems = useSelector((store) => store.gd["menuArr"]);

  const state = useSelector((store) => store.burgerState);
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(Actions.setBurger(false));
  };

  function getMenu() {
    let menu = [];

    if (Object.keys(menuItems).length > 0) {
      for (let key in menuItems) {
        menu.push(
          <li className="burger-menu-item" key={"burger-item-" + key}>
            <DynamicLink
              className="btn burger-menu-link"
              data={menuItems[key].linkArr}
              onClick={handleCloseClick}
            >
              <span>{menuItems[key].title} </span>
            </DynamicLink>
          </li>
        );
      }
    }
    return menu;
  }

  useEffect(() => {
    document.body.style.overflow = state ? "hidden" : "auto";
  }, [state]);

  return (
    <div
      className={"burger-menu-wrapper " + (state ? "active" : "")}
      onClick={handleCloseClick}
    >
      <div className="burger-menu">
        <div className={"close-icon"} onClick={handleCloseClick}>
          <img src={close} alt={""} />
        </div>
        <ul className="burger-menu-list">
        
          {getMenu()}
        </ul>
      </div>
    </div>
  );
}
