import React from "react";
import { useSelector } from "react-redux";

import "./top-part.scss";
import Checked from "assets/icons/checked.svg";

function TopPart(props) {
  const text = useSelector((store) => store.gd.translationsArr);

  const data = [
    { text: text["service_centers_service_include_part_1"], id: 0 },
    { text: text["service_centers_service_include_part_2"], id: 1 },
    { text: text["service_centers_service_include_part_3"], id: 2 },
    { text: text["service_centers_service_include_part_4"], id: 3 },
    { text: text["service_centers_service_include_part_5"], id: 4 },
  ];

  return (
    <div className="treatment-top-part-wrapper">
      <h3 className="title">{text["service_centers_treatment_page_title"]}</h3>
      <p className="subtitle">
        {text["service_centers_treatment_page_subtitle"]}
      </p>
      <div className="treatment-includes">
        <h3 className="includes-title">
          {text["service_centers_service_includes"]}
        </h3>
        <div className="treatment-includes-content">
          {data.map((item) => {
            return (
              <div className="include-item" key={item.id}>
                <div className="icon-wrapper">
                  <img src={Checked} alt="checked" />
                </div>
                <p className="text">{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TopPart;
