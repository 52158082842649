import { createSlice } from "@reduxjs/toolkit";

let Slices = [];
let formReducers = {};
let formActions = {};

export const contactSlice = createSlice({
  name: "contactForm",
  initialState: {},
  reducers: {
    updateContactForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetContactForm: () => {
      return {};
    },
  },
});
Slices.push(contactSlice);

export const treatmentSlice = createSlice({
  name: "treatmentForm",
  initialState: {},
  reducers: {
    updateTreatmentForm: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetTreatmentForm: (state, action) => {
      return {};
    },
  },
});
Slices.push(treatmentSlice);

export const agentFormSlice = createSlice({
  name: "agentForm",
  initialState: {},
  reducers: {
    updateAgentForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
Slices.push(agentFormSlice);

export const dynamicFormSlice = createSlice({
  name: "dynamicForm",
  initialState: {},
  reducers: {
    updateDynamicForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
Slices.push(dynamicFormSlice);


export const batteryFormSlice = createSlice({
  name: "batteryForm",
  initialState: {},
  reducers: {
    updateBatteryForm: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
Slices.push(batteryFormSlice);
//build export objects
for (const Slice of Slices) {
  formActions = { ...formActions, ...Slice.actions };
  let reducer = { [Slice.name]: Slice.reducer };
  formReducers = { ...formReducers, ...reducer };
}

export { formActions };
export { formReducers };
