import React, {Component} from 'react';
import { connect }        from 'react-redux';

import * as Popups from 'constants/popup-types';
import Actions     from 'redux/actions';
import './index.scss';

class Tool extends Component {

    showAPopup = () => {
        let payload = {text:'test message'};
        let payload2 = {text:'test message 2'};
        this.props.addPopup({type: Popups.GENERAL_MESSAGE, payload:payload});
    }

    render() {
        
        return (
            <div className="tool-wrapper">
                <div className="component">
                    <div className="component-title">Basic Popup</div>
                    <div className="component-usage">
                        Container responsible for displaying popups. Implemented in App.js.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label"><b>Locations:</b></div>
                    <div className="label">/popups - parent component. Matches popup types to specific popup components.</div>
                    <div className="label">/constants/popup-types.js - Popup type constants used to reference specific popup components</div>
                    <div className="label">/popups/components - Folder for popup components</div>
                </div>
                <div className="import-strip">
                    <div className="label"> <b> Actions: </b></div>
                    <div className="label">addPopup(<i>object</i>) - adds a popup to the redux popups array</div>
                    <div className="label">removePopup() - removes the last popup that was added</div>
                    <pre>
{`{
    type:`}<i> string </i>{`
    payload:`} <i>object</i>(optional){`
}`}
                    </pre>
                </div>
                <div className="code-sample">
                    <code>
                        <b>{`In App.js:`}</b>
                        <br />
                        {`let showPopup = this.props.popupsArray.length > 0;`}
                        <br />
                        {`{ showPopup && <Popups />}`}
                    </code>
                </div>
                <div className="note">
                    <div className="note-label">note:</div>
                    <span>Copy paste the basic popup in order to create new ones.</span>
                </div>
                <button className="button" onClick={ this.showAPopup }>test popup</button>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {
        addPopup: (payload) => dispatch(Actions.addPopup(payload))
    }
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Tool);
