import RadioButton from "components/forms/radio-button";
import { useState } from "react";

const genders = [
  { text: "זכר", id: "male" },
  { text: "נקבה", id: "female" },
  { text: "אחר", id: "other" },
];
function Tool() {
  const [genderState, setGenderState] = useState();
  const handleRadioChange = (name, value) => {
    setGenderState(value);
  };
  return (
    <div className="tool-wrapper with-preview">
      <div className="left">
        <div className="component">
          <div className="component-title">Radiobutton</div>
          <div className="component-usage">Styled radiobutton</div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Location:</b>
          </div>
          <div className="label">
            /components/ forms/ radio-button - parent component.
          </div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Props:</b>
          </div>
          <div className="label">
            options - a list of objects with two attributes id and text
          </div>
          <div className="label">value - the value from redux</div>
          <div className="label">
            onChange - onChange function. needs to recive two properties name
            and id. name is the name saved in redux.
          </div>
          <div className="label">name - the name whice is saved in redux</div>
          <div className="label">
            className - class added to the "radios-wrapper" class
          </div>
          <div className="label">showError - if error need to be shown</div>
          <div className="label">errorMessage - the error to show</div>
          <div className="label">
            emptyImage - image for an empty radiobutton
          </div>
          <div className="label">
            checkedImage - image for a checked radiobutton
          </div>
        </div>
        <div className="code-sample">
          <code>
            {` <RadioButton
              options={options}
              name="name"
              value={value from redux}
              onChange={onChangeFunction}
              checkedImage={radioCheckedImage}
              emptyImage={radioEmptyImage}
              showError={showErrorFunction() => true | false}
              errorMessage={message}
            />`}
          </code>
        </div>
        <div className="code-sample">
          <div className="label">Example for on Change function</div>
          <code>
            {` const handleSelectChange = (name, value) =>{
                    updateForm({ [name]: value });}`}
          </code>
        </div>
      </div>
      <div className="right">
        <RadioButton
          options={genders}
          name="gender"
          value={genderState}
          onChange={handleRadioChange}
        />
      </div>
    </div>
  );
}

export default Tool;
