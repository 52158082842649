import React         from 'react';
import { Component } from 'react';
import { connect }   from 'react-redux';
import Actions       from 'redux/actions';
import './index.scss';

import Collapse from 'components/common/collapse';

class Tool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open:false
        }
    }
    showContent = () => {
        let state = !this.state.open;
        this.setState({open:state});
    }

    render() {
        return (
            <div className="tool-wrapper with-preview">
                <div className="left">
                    <div className="component">
                        <div className="component-title">Collapse</div>
                        <div className="component-usage">
                            Wrap collapsible components to gain smooth animation according to calculated content height.
                        </div>
                    </div>
                    <div className="import-strip">
                        <div className="label">import Collapse from 'components/common/collapse';</div>
                    </div>
                    <div className="import-strip">
                        <div className="label"> <b> Props: </b></div>
                        <div className="label">open(<i>boolean</i>)</div>
                    </div>
                    <div className="code-sample">
                        <code>
                            {`<Collapse>
                                <div className="content">content content content...</div>
                            </Collapse>`}
                        </code>
                    </div>
                </div>
                <div className="right">
                    <button className="button" onClick={ this.showContent }>show content</button>
                    <Collapse open={ this.state.open }>
                        <div className="content">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {
        setLoader: (payload) => dispatch(Actions.setLoader(payload))
    }
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Tool);
