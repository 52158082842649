import React from "react";

import "./index.scss";

function ImageTexCard(props) {
  const { title, subtitle, text, dir, image } = props;
  return (
    <div className={"image-card-text-wrapper " + dir}>
      <div className="image-card-wrapper">
        <img src={image} alt="" />
      </div>
      <div className="card-conetnt">
        <h3 className="title">{title}</h3>
        {subtitle && <h3 className="title">{subtitle}</h3>}
        <p className="text">{text}</p>
      </div>
    </div>
  );
}

export default ImageTexCard;
