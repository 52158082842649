import { useState } from "react";
import "./index.scss";
import Select from "components/forms/select/index";

function Tool() {
  const [selectedId, setSelectedId] = useState(-1);

  function onChangeHandler(name, id) {
    setSelectedId(id);
  }
  return (
    <div className="tool-wrapper with-preview">
      <div className="left">
        <div className="component">
          <div className="component-title">Select</div>
          <div className="component-usage">Styled Select</div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Location:</b>
          </div>
          <div className="label">
            /components/ forms/ select - parent component.
          </div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Props:</b>
          </div>
          <div className="label">label - the placeholder</div>
          <div className="label">
            options - a list of objects with two attributes id and text
          </div>
          <div className="label">disabled - if the select is disabled</div>
          <div className="label">
            mobileNativeSelect - if the styled component is rendered on mobile
            or the default select
          </div>
          <div className="label">
            selectedId - the selected id whice is saved in redux
          </div>
          <div className="label">
            onChange - onChange function. needs to recive two properties name
            and id. name is the name saved in redux.
          </div>
          <div className="label">name - the name whice is saved in redux</div>
          <div className="label">
            className - class added to the "select-wrapper" class
          </div>
          <div className="label">showError - if error need to be shown</div>
          <div className="label">errorMessage - the error to show</div>
          <div className="label">
            dropDownImg - image for the drop down icon
          </div>
        </div>

        <div className="code-sample">
          <code>
            {`<Select
              label="placeholder"
              name = "name"
              options={options}
              onChange={onChangeFunction}
              mobileNativeSelect={false | true}
              selectedId = {id from redux}
              showError={showErrorFunction() => true | false}
              errorMessage={message}
            />`}
          </code>
        </div>
        <div className="code-sample">
          <div className="label">Example for on Change function</div>
          <code>
            {` const handleSelectChange = (name, id) =>{
                    updateForm({ [name]: id });}`}
          </code>
        </div>
      </div>
      <div className="right">
        <Select
          label="דוגמא"
          name="city"
          options={CityOptions}
          onChange={onChangeHandler}
          mobileNativeSelect={false}
          selectedId={selectedId}
        />
      </div>
    </div>
  );
}
export default Tool;

const CityOptions = [
  { text: "תל אביב", id: 0 },
  { text: "הרצליה", id: 1 },
  { text: "יבנה", id: 2 },
  { text: "כפר סבא", id: 3 },
];
