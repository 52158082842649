import React, { useState } from "react";
import "./index.scss";
import plus from "assets/part-seven/plus.svg";
import Collapse from "components/common/collapse";
import { googleAnalyticsManager } from "app/GoogleAnalytics/GoogleAnalyticsManager";

export default function QuestionItem(props) {
  const [open, setOpen] = useState(false);
  const { questionItem } = props;

  const { question = "", answer = "" } = questionItem;


  const handleCollapse = () => {
    googleAnalyticsManager.clickOnText(question)
    setOpen((prevState) => !prevState);
  };

  return (
    <div className={"questions-item"}>
      <div className={"question-wrapper"} onClick={handleCollapse}>
        <img
          className={`plus-img ${open ? "active" : ""}`}
          src={plus}
          alt={""}
        />
        <div className={"question-text"}>{question}</div>
      </div>
      <Collapse open={open}>
        <div className="answer-text">{answer}</div>
      </Collapse>
    </div>
  );
}
