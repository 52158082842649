import React, {Component} from 'react';
import {connect}          from 'react-redux';
import {Switch, Route}    from 'react-router-dom';
import Api                from 'api/requests';

//import components
import Header      from 'containers/header';
import MetaTags    from 'components/meta_tags';
import ScreenLoader      from 'components/common/loaders/screen';
import ScrollToTop from 'components/routing/scroll_top';

//Import containers
import Home    from 'containers/home';
import Contact from 'containers/contact';
import Content from 'containers/content-page';
import Popups  from 'popups';
import Page404 from 'containers/page404';
import Treatment from "containers/Treatment/Treatment";

//remove in production
import Docs from 'dev/docs';
import AllComponents from 'dev/all-components';

//css  
import 'styles/app.scss';
import 'styles/fonts.scss';

//routing constants
import * as Routes from 'constants/routes';
import Footer from "./containers/footer";


class App extends Component {
    constructor(props){
        super(props);

        this.state = {
            initialRequestsDone: false,
            showLoader: this.props.loaderState,
            startingLoadingTime: 0
        }
    }

    componentDidMount() {
        //initial requests
        Api.getHostUrl({config:{method:'get'}}).then((response)=>{
            if(response?.status){
                Api.generalDeclaration().then(()=>{
                    this.setState({initialRequestsDone:true});
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.showLoader != this.props.loaderState) {
            if (this.props.loaderState) {
                this.setState(state => {
                    return {...state, showLoader: this.props.loaderState, startingLoadingTime: new Date().getTime()}
                });
            } else {
                const currentTime = new Date().getTime();
                const diff = currentTime - prevState.startingLoadingTime;
                const minDelay = 2000;
                setTimeout(() => this.setState({showLoader: this.props.loaderState}),
                    diff < minDelay ? minDelay - diff : 0);
            }
        }
    }

    render() {
        let showPopup  = this.props.popupsArray.length > 0;
        //let showLoader = this.props.loaderState;
        let renderPage = this.props.deviceState && this.state.initialRequestsDone;

        //template object for dynamic page routes (content pages)
        const data = [
            {path: '/content1', component: Content},
            {path: '/content2', component: Content},
            {path: '/content3', component: Content}
        ];

        return (
          <div className="App">
            {renderPage && (
              <>
                <Header />

                {/* main routing table */}
                <Switch>
                  {/* regular routes */}
                  <Route exact path={Routes.root} component={Home} />
                  <Route exact path={Routes.treatment} component={Treatment} />

                  {/* template for dynamic pages */}
                  {data.map((page) => (
                    <Route
                      key={page.path}
                      exact
                      path={page.path}
                      component={page.component}
                    />
                  ))}

                  {/* defaults to 404 if none are found */}
                  <Route component={Page404} />
                </Switch>

                {/* make sure new page scroll position is at the top */}
                <ScrollToTop />

                {/* render meta tags from the server */}
                <MetaTags />

                <Footer />
              </>
            )}
            {/* main modal component */}
            {showPopup && <Popups />}

            {/* full page loader */}
            {this.state.showLoader && <ScreenLoader />}
          </div>
        );
    }
}

//get required state from redux store and map them to the components props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
        loaderState: store.loaderState,
        popupsArray: store.popupsArray,
    }
}
export default connect(mapStateToProps, null, null, {pure: false})(App);
