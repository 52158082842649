import React, {Component} from 'react';
import { connect }        from 'react-redux';
import Actions from 'redux/actions';
import './index.scss';

class Tool extends Component {
    
    doSomeAsyncThing = () => {
        this.props.setLoader(true);
        setTimeout(()=>{
            this.props.setLoader(false);
        },3000);
    }

    render() {
        return (
            <div className="tool-wrapper">
                <div className="component">
                    <div className="component-title">Screen Loader</div>
                    <div className="component-usage">
                        Simple full screen loader controlled by redux action. Implemented in App.js.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">import ScreenLoader from 'components/common/loaders/screen';</div>
                </div>
                <div className="import-strip">
                    <div className="label"> <b> Actions: </b></div>
                    <div className="label">setLoaderState(<i>boolean</i>)</div>
                </div>
                <div className="code-sample">
                    <code>
                        {`<ScreenLoader />`}
                    </code>
                </div>
                <div className="note">
                    <div className="note-label">note:</div>
                    <span>It's only a basic loader, tailor it to your specific project</span>
                </div>
                <button className="button" onClick={ this.doSomeAsyncThing }>test loader</button>
            </div>
        );
    }
}

//add GD categories and deviceState to props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
    }
}
//map a doLgin function to props
const mapDispatchToProps = dispatch => {
    return {
        setLoader: (payload) => dispatch(Actions.setLoader(payload))
    }
}

//connect to redux store - maps dispatch and redux state to props
export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Tool);
