import React         from 'react';
import { Component } from 'react';
import { connect }   from 'react-redux';
import { Switch }    from 'react-router-dom';
import { Route }     from 'react-router-dom';
import { NavLink }   from 'react-router-dom';
import { Redirect }  from 'react-router-dom';

//Import documentation
import Scroll from './scrolltop';
import Meta   from './meta';
import ScreenLoader from './loaders/screen';
import ComponentLoader from "./loaders/component";
import BasicPopup from "./popups/basic";
import TwoActionPopup from "./popups/two_action";
import Collapse from './collapse';
import AnimatedInput from './animated_input';
import AnimatedTextArea from './animated_textarea';
import Checkbox from "./checkbox";
import Select from './select';
import RadioButton from './radiobutton';
import Timer from "./timer"
//css
import './index.scss';


//routing constants
import * as Routes from 'dev/docs/doc-routes';


class Components extends Component {
    render() {

        return (
            <div className="components-wrapper">
                <div className="menu-wrapper">
                    <NavLink to={Routes.scroll} exact activeClassName='active'
                             className="docs-item">ScrollToTop</NavLink>
                    <NavLink to={Routes.meta} exact activeClassName='active' className="docs-item">MetaTags</NavLink>
                    <div className={"docs-item dropdown"}>
                        <span
                            className={`dropdown-trigger ${Object.values(Routes.loaders).indexOf(this.props.location.pathname) > -1 ? 'active' : ''}`}>Loaders</span>
                        <div className="dropdown-content">
                            <NavLink to={Routes.loaders.screen_loader} exact activeClassName='active' className="docs-item">Screen Loader</NavLink>
                            <NavLink to={Routes.loaders.component_loader} exact activeClassName='active'
                                     className="docs-item">Component Loader</NavLink>
                        </div>
                    </div>
                    <div className={"docs-item dropdown"}>
                        <span
                            className={`dropdown-trigger ${Object.values(Routes.popups).indexOf(this.props.location.pathname) > -1 ? 'active' : ''}`}>Popups</span>
                        <div className="dropdown-content">
                            <NavLink to={Routes.popups.basic_popup} exact activeClassName='active'
                                     className="docs-item">Basic Popup</NavLink>
                            <NavLink to={Routes.popups.two_action_popup} exact activeClassName='active'
                                     className="docs-item">Two Action Popup</NavLink>
                        </div>
                    </div>
                    <NavLink to = { Routes.collapse } exact activeClassName='active' className="docs-item">Collapse</NavLink>
                    <NavLink to = { Routes.checkbox } exact activeClassName='active' className="docs-item">Checkbox</NavLink>
                    <NavLink to = { Routes.animated_input } exact activeClassName='active' className="docs-item">Animated input</NavLink>
                    <NavLink to = { Routes.animated_textarea } exact activeClassName='active' className="docs-item">Animated textarea</NavLink>
                    <NavLink to = { Routes.select } exact activeClassName='active' className="docs-item">Select</NavLink>
                    <NavLink to = { Routes.radiobutton } exact activeClassName='active' className="docs-item">RadioButton</NavLink>
                    <NavLink to = { Routes.timer } exact activeClassName='active' className="docs-item">Timer</NavLink>

                </div>
                <Switch>
                    <Route exact path= { Routes.components }>
                        <Redirect to={ Routes.scroll } />
                    </Route>
                    <Route exact path={Routes.scroll} component={Scroll}/>
                    <Route exact path={Routes.meta} component={Meta}/>
                    <Route exact path={Routes.loaders.screen_loader} component={ScreenLoader}/>
                    <Route exact path={Routes.loaders.component_loader} component={ComponentLoader}/>
                    <Route exact path={Routes.popups.basic_popup} component={BasicPopup}/>
                    <Route exact path={Routes.popups.two_action_popup} component={TwoActionPopup}/>
                    <Route exact path={Routes.collapse} component={Collapse}/>
                    <Route exact path={Routes.animated_input} component={AnimatedInput}/>
                    <Route exact path={Routes.animated_textarea} component={AnimatedTextArea}/>
                    <Route exact path={ Routes.checkbox } component={ Checkbox }/>
                    <Route exact path={ Routes.select } component={ Select }/>
                    <Route exact path={ Routes.radiobutton } component={ RadioButton }/>
                    <Route exact path={ Routes.timer } component={ Timer }/>

                </Switch>
            </div>
        )
    }
}

//get required state from redux store and map them to the components props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
    }
}
export default connect(mapStateToProps, null, null, { pure: false })(Components);
