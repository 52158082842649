import React         from 'react';
import { Component } from 'react';
import { connect }   from 'react-redux';
import { Switch }    from 'react-router-dom';
import { Route }     from 'react-router-dom';
import { NavLink }   from 'react-router-dom';
import { Redirect }  from 'react-router-dom';

//Import documentation
import Device from './device';

//css
import './index.scss';

//routing constants
import * as Routes from 'dev/docs/doc-routes';


class Tools extends Component {
    render() {
        
        return (
            <div className="tools-wrapper">
                <div className="menu-wrapper">
                    <NavLink to = { Routes.device } exact activeClassName='active' className="docs-item">deviceState</NavLink>
                </div>
                <Switch>
                    <Route exact path= { Routes.tools }>
                        <Redirect to={ Routes.device } />
                    </Route>
                    <Route exact path={ Routes.device } component={ Device }/>
                </Switch>
            </div>
        )
    }
}

//get required state from redux store and map them to the components props
const mapStateToProps = store => {
    return {
        deviceState: store.deviceState,
    }
}
export default connect(mapStateToProps, null, null, { pure: false })(Tools);
