export const tools                  = '/docs/tools';
export const device                 = '/docs/tools/device';

export const components             = '/docs/components';
export const scroll                 = '/docs/components/scroll';
export const meta                   = '/docs/components/meta';
export const loaders                = {screen_loader: '/docs/components/screen_loader', component_loader: '/docs/components/component_loader'};
export const popups                 = {basic_popup: '/docs/components/basic_popup', two_action_popup: '/docs/components/two_action_popup'};
export const collapse               = '/docs/components/collapse';
export const checkbox               = '/docs/components/checkbox';
export const animated_input         = '/docs/components/animated_input';
export const animated_textarea      = '/docs/components/text_area';
export const select                 = '/docs/components/select';
export const radiobutton            = '/docs/components/radiobutton';
export const timer                  = "/docs/components/timer";

export const containers = '/docs/containers';