import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Api from "api/requests";

import CustomAutoComplete from "components/forms/custom-autocomplete/CustomAutoComplete";
import CustomInput from "components/forms/custom-input/CustomInput";

import "./PersonalDetails.scss";
function PersonalDetails(props) {
  const { isValidFunction, handleChange } = props;
  const treatmentForm = useSelector((store) => store.treatmentForm);
  const text = useSelector((store) => store.gd.translationsArr);
  const [isCitySelected, setIsCitySelected] = useState(false);
  const cities = useSelector((store) => store.gd.citiesArr);
  const streets = useSelector((store) => store.streets);

  // const [streets, setStreets] = useState([]);

  useEffect(() => {
    setIsCitySelected(
      treatmentForm.city !== -1 && treatmentForm.city !== undefined
    );

    Api.getCityStreets({
      payload: { city_id: treatmentForm.city },
    });
    handleChange("street", -1);
  }, [treatmentForm.city]);

  return (
    <div className="personal-details-wrapper">
      <h3 className="title">{text["service_centers_pickup_car_title"]}</h3>
      <div className="personal-details-inputs">
        <div className="personal-details-address">
          <CustomAutoComplete
            className="field"
            options={cities}
            placeholder={text["service_centers_city"]}
            name="city"
            isValid={isValidFunction("city")}
            onSelected={handleChange}
            selectedId={treatmentForm.city}
            disabled={false}
          />

          <CustomAutoComplete
            className="field"
            options={streets}
            placeholder={text["service_centers_street"]}
            name="street"
            isValid={isValidFunction("street")}
            onSelected={handleChange}
            selectedId={treatmentForm.street}
            disabled={!isCitySelected}
          />

          <CustomInput
            className="field"
            label={text["service_centers_home_number"]}
            value={treatmentForm.houseNo}
            name="houseNo"
            isValid={isValidFunction("houseNo")}
            needToShowBtn={false}
            onChange={handleChange}
            type="number"
            isNegativeAllowd={false}
          />
        </div>
        <div className="personal-details-fullname">
          <CustomInput
            className="field"
            label={text["service_centers_first_name"]}
            value={treatmentForm.firstName}
            name="firstName"
            isValid={isValidFunction("firstName")}
            needToShowBtn={false}
            onChange={handleChange}
            type="text"
          />
          <CustomInput
            className="field"
            label={text["service_centers_last_name"]}
            value={treatmentForm.lastName}
            name="lastName"
            isValid={isValidFunction("lastName")}
            needToShowBtn={false}
            onChange={handleChange}
            type="text"
          />
        </div>
        <div className="personal-details-contact">
          <CustomInput
            className="field"
            label={text["service_centers_cell_phone"]}
            value={treatmentForm.phone}
            name="phone"
            isValid={isValidFunction("phone")}
            needToShowBtn={false}
            onChange={handleChange}
            type="number"
            isNegativeAllowd={false}
          />
          <CustomInput
            className="field"
            label={text["service_centers_email"]}
            value={treatmentForm.email}
            name="email"
            isValid={isValidFunction("email")}
            needToShowBtn={false}
            onChange={handleChange}
            type="text"
          />
        </div>
        <CustomInput
          className="field"
          label={text["service_centers_comments"]}
          value={treatmentForm.notes}
          name="notes"
          isValid={isValidFunction("notes")}
          needToShowBtn={false}
          onChange={handleChange}
          type="text"
        />
      </div>
    </div>
  );
}

export default PersonalDetails;
