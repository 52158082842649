import React, {useEffect, useState} from 'react';
import './index.scss';

const ComponentLoader = (props) => {

    const [isLoading, setIsLoading] = useState(props.loaderState);

    useEffect(() => {
        setIsLoading(props.loaderState);
    }, [props.loaderState])

    return (
        <>
            {isLoading && <div className="loader"></div>}
        </>
    );
}
export default ComponentLoader;