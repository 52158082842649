import React, { Component } from "react";
import { connect, useSelector } from "react-redux";

import DynamicLink from "components/dynamic_link";

import "./index.scss";

const Footer = () => {
  const deviceState = useSelector((store) => store.deviceState);
  const isMobile = deviceState.notDesktop;
  const footerItems = useSelector((store) => store.gd["footerArr"]);

  function getFooterItems() {
    let links = [];

    if (Object.keys(footerItems).length > 0) {
      for (let key in footerItems) {
        links.push(
          <DynamicLink
            className="footer-item"
            data={footerItems[key].linkArr}
            key={key}
          >
            {footerItems[key].title}
          </DynamicLink>
        );
      }
    }
    return links;
  }

  return (
    <div className="footer">
      <div className={"footer-line"} />

      <div className={"footer-items-wrapper"}>
        {getFooterItems()}
        {!isMobile && (
          <div className={"footer-bottom-text"}>
            Developed by Inmanage / Branded by Commando Solo
          </div>
        )}
      </div>
      {isMobile && (
        <div className={"footer-bottom-text"}>
          Developed by Inmanage / Branded by Commando Solo
        </div>
      )}
    </div>
  );
};

export default Footer;
