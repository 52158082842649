import "./index.scss";
import { useState } from "react";

import Checkbox from "components/forms/checkbox";
import checkedImage from "../../../../assets/checkbox/checked-checkbox.svg";
import emptyImage from "../../../../assets/checkbox/empty-checkbox.svg";

function Tool(props) {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="tool-wrapper with-preview">
      <div className="left">
        <div className="component">
          <div className="component-title">Checkbox</div>
          <div className="component-usage">Simple styled checkbox</div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Location:</b>
          </div>
          <div className="label">
            /components/ forms/ checkbox - parent component.
          </div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Photos:</b>
          </div>
          <div className="label">
            The checkbox expect to get two images one of empty checkbox the
            second one is of checked checkbox, if one of the photos is mising it
            will be the defaulted to the regular checkbox
          </div>
        </div>
        <div className="import-strip">
          <div className="label">
            <b>Value:</b>
          </div>
          <div className="label">
            The value of the checkbox needs to be true or false, whice is saved
            in the store by redux
          </div>
        </div>
        <div className="code-sample">
          <code>
            {`  <Checkbox
              type="checkbox"
              name="name"
              id="id"
              label="דוגמא"
              value={true | false}
              onChange={onChangeFunction}
              checkedImage={checkedImage}
              emptyImage={emptyImage}
              showError={showErrorFunction() => true | false}
              errorMessage={message}
            />`}
          </code>
        </div>
      </div>
      <div className="right">
        <Checkbox
          type="checkbox"
          name="example"
          id="example"
          label="example"
          value={isChecked}
          onChange={() => {
            setIsChecked((prevState) => {
              return !prevState;
            });
          }}
          checkedImage={checkedImage}
          emptyImage={emptyImage}
        />
      </div>
    </div>
  );
}

export default Tool;
