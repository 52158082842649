import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

//Components
import DesktopMenu from "./DesktopMenu";
import BurgerMenu from "./BurgerMenu";

//Assets
import "./index.scss";
import burger from "assets/icons/burger-icon.svg";
import logo from "assets/logos/shlomo-logo.svg";
import phoneNum from "assets/icons/phone-num.svg";

//routing constants
import * as Routes from "constants/routes";

//Actions
import Actions from "redux/actions";
import { NavLink } from "react-router-dom";

function Header() {
  const deviceState = useSelector((store) => store.deviceState);
  const phoneNumber = useSelector((store) => store.gd.top_header_phone);
  const linkLogo = useSelector((store) => store.gd.top_header_icon_link);

  const dispatch = useDispatch();
  const handleBurgerClick = () => {
    dispatch(Actions.setBurger(true));
  };

  return (
    <header className="header-wrapper">
      <div className="header">
        <div className="burger-icon" onClick={handleBurgerClick}>
          <img src={burger} alt="" />
        </div>
        <BurgerMenu />
        <a href={"tel:" + phoneNumber} className="phone-num">
          {phoneNumber}
        </a>

        <a href={linkLogo} target="_self" >
          <img src={logo} className="logo" alt="" />
        </a>
      </div>
    </header>
  );
}

export default Header;
