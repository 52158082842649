export const googleAnalyticsManager = (function () {
  const category = decodeURI(window.location.host + window.location.pathname);

  const clickOnButton = (label) => {
    const payload = {
      event: "Click on Button",
      category: category,
      action: "Click on Button",
      label: label,
    };
    push(payload);
  };

  const clickOnText = (label) => {
    const payload = {
      event: "Click on Text",
      category: category,
      action: "Click on Text",
      label: label,
    };
    push(payload);
  };

  const lead = (label) => {
    // label => Lead - Battery up to me
    // or => Lead – Insurance Agents
    // or => Lead – Home car repair
    const payload = {
      event: "Lead",
      category: category,
      action: "Lead",
      label: label,
    };
    push(payload);
  };

  const licenseNum = () => {
    const payload = {
      event: "vehicle license num success",
      category: category,
      action: "license num sent",
      label: "vehicle license num success",
    };
    push(payload);
  };

  const push = (payload) => {
    window.dataLayer.push(payload);
  };

  return {
    clickOnButton: clickOnButton,
    clickOnText: clickOnText,
    lead: lead,
    licenseNum: licenseNum,
  };
})();
