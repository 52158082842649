import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import Actions from "redux/actions";

import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import he from "date-fns/locale/he";
import leftArrow from "assets/icons/left-arrow.svg";
import rightArrow from "assets/icons/right-arrow.svg";

const months = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

function CustomDatePicker(props) {
  const {
    date,
    minDate = new Date(),
    setDate,
    name,
    label,
    className = "",
    icon,
    isValid = true,
    extraText = undefined,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const dispatch = useDispatch();
  const text = useSelector((store) => store.gd.translationsArr);

  function onOpenBtnClickHandler(e) {
    setIsOpen((prevState) => !prevState);
  }
  useEffect(() => {
    dispatch(Actions.setBackdrop(isOpen));
  }, [isOpen]);
  useEffect(() => {
    if (date) {
      setIsDateSelected(true);
    }
  }, [date]);

  function onContinueBtnClickHandler(e) {
    setIsOpen(false);
  }

  function getDate(currentDate) {
    return (
      currentDate.getDate() +
      "/" +
      ("0" + (currentDate.getMonth() + 1)).slice(-2) +
      "/" +
      currentDate.getFullYear().toString().substr(-2)
    );
  }
  const disableWeekends = (current) => {
    return current.getDay() !== 5 && current.getDay() !== 6;
  };

  function getLabel() {
    if (!date) return label;
    else {
      const currentDate = getDate(date);
      if (extraText) {
        return (
          <p className="selected-date-label">
            {extraText}
            <span>&nbsp;</span>

            <span className="selected-date">{currentDate}</span>
          </p>
        );
      } else {
        return <span className="selected-date">{currentDate}</span>;
      }
    }
  }

  return (
    <div
      className={`custom-date-picker ${isValid ? "" : "unvalid"} ${className} ${
        isOpen ? "open" : ""
      }`}
    >
      <button
        className={`btn-date-picker ${isDateSelected ? "date-selected" : ""}`}
        onClick={onOpenBtnClickHandler}
      >
        {getLabel()}
        <div className={`image-wrapper`}>
          <img src={icon}></img>
        </div>
      </button>

      {isOpen && (
        <Fragment>
          <DatePicker
            open={isOpen}
            onClickOutside={onOpenBtnClickHandler}
            filterDate={disableWeekends}
            selected={date}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(4, nameOfDay.length)}
            locale={he}
            rtl
            inline
            shouldCloseOnSelect={false}
            minDate={minDate}
            onChange={(date) => setDate(name, date)}
            disabledKeyboardNavigation
            dayClassName={(date) =>
              new Date().getDate() === date.getDate() ? "selected" : ""
            }
            renderCustomHeader={({
              date,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="header-wrapper">
                <button
                  className={`btn-nav ${
                    nextMonthButtonDisabled ? "disbaled" : ""
                  }`}
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img className="right-arrow arrow" src={leftArrow} />
                </button>
                <h3 className="month-year-title">
                  {months[date.getMonth()]} {date.getFullYear()}
                </h3>

                <button
                  className={`btn-nav ${
                    prevMonthButtonDisabled ? "disbaled" : ""
                  }`}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img className="left-arrow arrow" src={rightArrow} />
                </button>
              </div>
            )}
          >
            <div className={"btn-wrapper " + (date ? "selected" : "")}>
              <button
                className="continue-button"
                onClick={onContinueBtnClickHandler}
              >
                <span className="text">{text["continue_btn"]}</span>
              </button>
            </div>
          </DatePicker>
        </Fragment>
      )}
    </div>
  );
}

export default CustomDatePicker;
