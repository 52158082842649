import AppConfig from "app/config/AppConfig";

//returns full path of asset
export function getPath(path) {
  let Config = new AppConfig();
  return Config.debug_mode ? Config.media_path + path : path;
}

//returns full path of asset
export function getMediaPath(path) {
  let Config = new AppConfig();
  return Config.media_path + path;
}

//check for empty objects
export function notEmptyObject(obj) {
  let empty = true;
  if (obj) {
    if (Object.keys(obj).length !== 0 && obj.constructor === Object) {
      empty = false;
    }
  }
  return empty;
}

//Convert Object to sorted array by order_num
export function convertToSortedArray(obj) {
  let sortedArr = Object.keys(obj).map((key) => obj[key]);
  sortedArr = sortedArr.sort((a, b) => {
    return a.order_num - b.order_num;
  });
  return sortedArr;
}

//query browser for user location
export function getUserGeoLocation(onSuccess, onFailure) {
  if ("geolocation" in navigator) {
    // check if geolocation is supported/enabled on current browser
    navigator.geolocation.getCurrentPosition(
      function success(position) {
        // for when getting location is a success
        onSuccess(position);
      },
      function error(error_message) {
        // for when getting location results in an error
        // console.error('An error has occured while retrieving location', error_message);
        onFailure(error_message);
      }
    );
  } else {
    // geolocation is not supported
    // get your location some other way
    console.log("geolocation is not enabled on this browser");
  }
}

//calculate distance in Km between 2 gps sets
export function calcDistance(p1, p2) {
  var R = 6371; // Radius of the earth in km
  var dLat = toRad(p1.lat - p2.lat); // Javascript functions in radians
  var dLon = toRad(p1.lng - p2.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(p2.lat)) *
      Math.cos(toRad(p1.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return parseInt(d);
}
function toRad(num) {
  return (num * Math.PI) / 180;
}

export function getFullMediaUrl(path) {
  return mediaUrl + path;
}

//generate unique IDs
export function generateUniqueId(length) {
  var result = [];
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}
