import React, { Component } from "react";
import { connect, useSelector } from "react-redux";

import att1 from "assets/part-four/attributes/1.svg";
import att2 from "assets/part-four/attributes/2.svg";
import att3 from "assets/part-four/attributes/3.svg";
import att4 from "assets/part-four/attributes/4.svg";
import att5 from "assets/part-four/attributes/5.svg";
import att6 from "assets/part-four/attributes/6.svg";
import mobileBottomBg from "assets/part-four/bottom-bg.png";
import desktopBottomBg from "assets/part-four/desktop-bottom-bg.png";
import "./index.scss";
import { getFullMediaUrl, convertToSortedArray } from "app/functions";

const PartFour = () => {
  const iconsArr = useSelector(
    (store) => store.gd.icons_groupsArr.reliability_iconsArr
  );
  const deviceState = useSelector((store) => store.deviceState);
  const imagesArr = convertToSortedArray(iconsArr);

  const genralContent = useSelector(
    (store) => store.gd.general_content_imagesArr
  );
  const part4Content = genralContent["part4_content"];
  const mobilePhoto = getFullMediaUrl(part4Content?.imageArr?.mobile_path);
  const desktopPhoto = getFullMediaUrl(part4Content?.imageArr?.path);
  const title = part4Content.title;

  const mobileBottom = <img src={mobilePhoto} alt={""} />;
  const desktopBottom = <img src={desktopPhoto} alt={""} />;

  return (
    <div className="home-four">
      <div className={"four-title"}>{title}</div>
      <div className={"four-attributes-wrapper"}>
        {imagesArr.map((item, i) => {
          return (
            <div className={"four-attribute"} key={i}>
              <img src={getFullMediaUrl(item?.imageArr?.path)} alt={""} />
              <div className={"four-attribute-text"}>{item.title} </div>
            </div>
          );
        })}
      </div>
      {deviceState.isDesktop ? desktopBottom : mobileBottom}
    </div>
  );
};

export default PartFour;
